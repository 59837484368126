import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { set_loading } from "../../redux/actions/LayoutAction";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../assets/styles/footer-pages/offers.scss";
import ProductList from "../../components/product/OffersVariantList";

import a5 from "../../assets/images/offer2categories/WoodStains.png";
import a8 from "../../assets/images/offer2categories/Paint.png";
import a4 from "../../assets/images/offer2categories/SprayPaint.png";
import a3 from "../../assets/images/offer2categories/Sealant-more.png";
import a9 from "../../assets/images/offer2categories/Cleaner.png";
import a10 from "../../assets/images/individual-pages/paints/All-Purpose-Paint.png";

import VariantsModal from "../../components/customer/VariantsModal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const OffersSections = (props) => {
  const [offers_sections, setOffersSections] = useState([]);
  const [top_banner, setTopBanner] = useState();
  const [boxes_banners, setBoxesBanners] = useState([]);
  const { t } = useTranslation();
  const [variantsModalShow, setVariantsModalShow] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedSku, setSelectedSku] = React.useState(null);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  let sections = [];

  useEffect(() => {
    document.title = "Alisouq.com - Offers";
  }, []);

  useEffect(() => {
    apiJson.offers.getTopBanner(props.countryId).then(async (response) => {
      if (response.success) {
        setTopBanner(response.data);
      }
    });

    apiJson.offers.getBoxesBanners(props.countryId).then(async (response) => {
      if (response.success) {
        setBoxesBanners(response.data);
      }
    });

    apiJson.offers.offers_sections(props.countryId)
      .then(response => {
        if (response.success) {
          setOffersSections(response.data.sections);
        }
      });
  }, []);

  const onQuickViewClicked = (product, sku) => {
    setVariantsModalShow(true);
    setSelectedSku(sku);

    apiJson.
      singleItem.getItemData(product.id, product.product_type ? "products" : "services", props.countryId)
      .then(response => {
        response.data.type = "Product";

        setSelectedProduct(response.data);
      });
  }

  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <VariantsModal
          show={variantsModalShow}
          onHide={() => setVariantsModalShow(false)}
          closeModal={() => setVariantsModalShow(false)}
          product={selectedProduct}
          selected_sku={selectedSku}
        />
        <WebsiteHeader />

        <div className="content-wrapper all-page-container">
          <div className="offers-row">
            <div className="kid-fashion-banner-container">
              <a href="#">

                <div className="pagesbanner">
                  <img
                    src={top_banner?.website_image}
                    alt="category"
                    style={{
                      width: "100%",

                    }}
                  ></img>
                </div>

              </a>
            </div>
          </div>
          {
            boxes_banners.length > 0 ?
              <div className="row offerrow">
                {
                  boxes_banners.map((banner, key) => {
                    if (key < 3) {
                      return (
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                          <a href={banner.link}>
                            <img className="img-fluid"
                              src={banner.website_image}
                              alt="category"
                              style={{
                                width: "100%",
                              }}
                            ></img></a>
                        </div>
                      )
                    }
                  })
                }
              </div>
              :
              <></>
            // <div className="row offerrow">

            //   <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            //     <a href="offers/section/hot-deals">
            //       <img className="img-fluid"
            //         src={ramadanimg1}
            //         alt="category"
            //         style={{
            //           width: "100%",
            //         }}
            //       ></img></a>
            //   </div>

            //   <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            //     <a href="offers/section/buy-1-get-1">

            //       <img className="img-fluid"
            //         src={ramadanimg2}
            //         alt="category"
            //         style={{
            //           width: "100%",
            //         }}
            //       ></img>
            //     </a>
            //   </div>
            //   <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            //     <a href="offers/section/summer-specials">

            //       <img className="img-fluid"
            //         src={ramadanimg3}
            //         alt="category"
            //         style={{
            //           width: "100%",
            //         }}
            //       ></img>
            //     </a>
            //   </div>
            // </div>
          }
          <div className="emptyspacer"></div>
          <h2 className="sectiontitle-individualpage">Shop by Category</h2>
          <div className="emptyspacer"></div>
          <Swiper
            spaceBetween={40}
            slidesPerView={7}
            pagination={false}
            navigation={true}
            className="categories-swiper"
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/offers/section/wood-stains",
                // search: "filter_by_category%5B%5D=274"
              }}>
                <img
                  src={a5} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/offers/section/paint",
                // search: "filter_by_category%5B%5D=445"
              }}>
                <img
                  src={a8} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/offers/section/spray-paint",
                // search: "filter_by_category%5B%5D=442"
              }}>
                <img
                  src={a4} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/offers/section/sealants-and-more",
                // search: "filter_by_category%5B%5D=440"
              }}>
                <img
                  src={a3} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            {/* <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/offers/section/-krud-kutter-",
                // search: "filter_by_category%5B%5D=444"
              }}>
                <img
                  src={a9} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=445"
              }}>
                <img
                  src={a10} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide> */}
            {/* <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=423"
              }}>
                <img
                  src={one}
                  className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=439"
              }}>
                <img
                  src={two} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=438"
              }}>
                <img
                  src={five}
                  className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=271"
              }}>
                <img
                  src={six} className="img-img-fluid paintcat"
                  height="150px"
                />
              </Link>
            </SwiperSlide> */}

          </Swiper>
          {
            offers_sections.map((section, index) => {
              return (
                <>
                  {
                    index == 4 && (
                      <>
                        <div className="emptyspacer"></div>
                        {/* mybrands */}
                        <h2 className="sectiontitle-individualpage">Shop by Brands</h2>
                        <div className="emptyspacer"></div>
                        <Swiper
                          spaceBetween={40}
                          slidesPerView={7}
                          pagination={false}
                          navigation={true}
                          className="shop-by-brands"
                        >
                          <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                          >
                            <Link to={{
                              pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                              search: "filter_by_brand%5B%5D=42"
                            }}>
                              <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hSZ6J8vxQ8pENansr4opLk.png"
                                className="img-img-fluid paintbrand"
                                height="150px"
                              />
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                          >
                            <Link to={{
                              pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                              search: "filter_by_brand%5B%5D=48"
                            }}>
                              <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fwyPyYsDLWYNkLtUk8MS4E.png" className="img-img-fluid paintbrand"
                                height="150px"
                              />
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                          >
                            <Link to={{
                              pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                              search: "filter_by_brand%5B%5D=43"
                            }}>
                              <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iReSSYpWhmJcPBKxns3BgJ.png" className="img-img-fluid paintbrand"
                                height="150px"
                              />
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                          >
                            <Link to={{
                              pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                              search: "filter_by_brand%5B%5D=50"
                            }}>
                              <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7H64tmqZpw7VVwrbm5eB4v.png" className="img-img-fluid paintbrand"
                                height="150px"
                              />
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                          >
                            <Link to={{
                              pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                              search: "filter_by_brand%5B%5D=49"
                            }}>
                              <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/7ZMiKGr5RjQgKztQv6iQjt.png"
                                className="img-img-fluid paintbrand"
                                height="150px"
                              />
                            </Link>
                          </SwiperSlide>
                          <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                          >
                            <Link to={{
                              pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                              search: "filter_by_brand%5B%5D=56"
                            }}>
                              <img
                                src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5yEpHy771FyBamNNRqtbEB.png" className="img-img-fluid paintbrand"
                                height="150px"
                              />
                            </Link>
                          </SwiperSlide>
                        </Swiper>
                        <div className="emptyspacer"></div>
                      </>
                    )
                  }
                  <Row
                    key={0}
                    className={`product-list-row mx-auto p-0`}
                  >
                    <div className="title-row">
                      <div className={props.language == "en" ? "title" : "title text-right"}
                        dangerouslySetInnerHTML={{
                          __html: section.title_en,
                        }}
                      ></div>
                      <div class={props.language == "en" ? "viewalldiv" : "viewalldiv viewalldiv-ar"}>
                        <Link
                          to={
                            "/" + props.countryUrl + "-" + props.language + "/offers/section/" + section.url
                          }
                          className="view-all-text">
                          {t("all-categories.view-all")}
                        </Link>
                      </div>
                    </div>
                    <ProductList
                      type="product"
                      home={true}
                      products={section.offers_section_variants}
                      onQuickViewClicked={onQuickViewClicked}
                    />
                    <div />
                  </Row>
                </>
              )
            })
          }
        </div>

        <WebsiteFooter />

      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersSections);
