import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import "../../../assets/styles/category-homepages/ali-day-mob.scss";

import MobileLayoutRenderer from "../layout/MobileLayoutRenderer";
import HeaderMobile from "../../../components/mobile/HeaderMobile";

import alidaybanner from "../../../assets/images/aliday/alidaybannermob.jpg";
import alidayunit from "../../../assets/images/aliday/AliDay_Unit.png";

import NeverWetMob from "../../../assets/images/aliday/mob/NeverWet.jpg";

import catalog1 from "../../../assets/images/aliday/mob/Hardware.jpg";
import catalog2 from "../../../assets/images/aliday/mob/Paint.jpg";
import catalog3 from "../../../assets/images/aliday/mob/Electrical.jpg";
import catalog4 from "../../../assets/images/aliday/mob/Automotive.jpg";

import combo1 from "../../../assets/images/aliday/combo1.png";
import combo2 from "../../../assets/images/aliday/combo2.png";
import combo3 from "../../../assets/images/aliday/combo3.png";
import combo4 from "../../../assets/images/aliday/combo4.png";

import BrandFavourite from "../../../assets/images/aliday/Brand-Favourite.gif";

import one from "../../../assets/images/individual-pages/home-appliances/VacuumCleaners.png";
import two from "../../../assets/images/individual-pages/home-appliances/Washer.png";
import three from "../../../assets/images/individual-pages/home-appliances/Heater.png";
import four from "../../../assets/images/individual-pages/home-appliances/Microwave.png";
import five from "../../../assets/images/individual-pages/home-appliances/Refrigerator.png";
import seven from "../../../assets/images/individual-pages/home-appliances/Juicer.png";
import eight from "../../../assets/images/individual-pages/home-appliances/Coffee Maker.png";
import nine from "../../../assets/images/individual-pages/home-appliances/Toaster.png";

import CampingTentsImg from "../../../assets/images/camping/section2/CampingTents.jpg";
import SleepingBagsImg from "../../../assets/images/camping/section2/SleepingBags.jpg";
import CampingFurnitureImg from "../../../assets/images/camping/section2/CampingFurniture.jpg";
import CharcoalImg from "../../../assets/images/camping/section2/Charcoal.jpg";
import FlashlightImg from "../../../assets/images/camping/section2/Flashlight.jpg";
import WaterBottleImg from "../../../assets/images/camping/section2/WaterBottle.jpg";
import CampingBackpacksImg from "../../../assets/images/camping/section2/CampingBackpacks.jpg";
import CampingUtensilsImg from "../../../assets/images/camping/section2/CampingUtensils.jpg";
import CampingCoolersImg from "../../../assets/images/camping/section2/CampingCoolers.jpg";
import BBQgrillsImg from "../../../assets/images/camping/section2/BBQgrills.jpeg";
import CampingToolsImg from "../../../assets/images/camping/section2/CampingTools.jpg";
import CapsCoolnetsImg from "../../../assets/images/camping/section2/Caps_coolnets.jpg";
import BinocularsImg from "../../../assets/images/camping/section2/Binoculars.jpg";

import eightp from "../../../assets/images/individual-pages/paints/All-Purpose-Paint.png";
import fourp from "../../../assets/images/individual-pages/paints/Primers-_-Stucco.png";
import ninep from "../../../assets/images/individual-pages/paints/Marking-Paints.png";
import threep from "../../../assets/images/individual-pages/paints/Exterior-Paint.png";
import onep from "../../../assets/images/individual-pages/paints/Rollers.png";
import sevenp from "../../../assets/images/individual-pages/paints/Brushes.png";
import fivep from "../../../assets/images/individual-pages/paints/Acrylic-Paints.png";
import twop from "../../../assets/images/individual-pages/paints/Interior-Paint.png";
import tenp from "../../../assets/images/individual-pages/paints/Wood-Finishes.png";
import sixp from "../../../assets/images/individual-pages/paints/Epoxy.png";

import cat1 from "../../../assets/images/aliday/BuildingandConstruction.jpg";
import cat2 from "../../../assets/images/aliday/Home-Improvement.jpg";
import cat3 from "../../../assets/images/aliday/DailyEssentials.jpg";

import { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const AliDayMobPage = (props) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    window.document.title = "ALIDAY SALE - ALISOUQ";

    $("html, body").animate({ scrollTop: 0 }, "fast");

    apiJson.
      sections.getCamping1Section(props.countryId)
      .then(response => {
        if (response.success) {
          setProducts(response.data.section.products);
        }
      });
  }, []);

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
      SearchIcon={true}
    >
      <div className="home-mob">
        <HeaderMobile />
      </div>

      <div className="ali-day-mob">
        {/* <img
          src={alidaybanner}
          className="img img-fluid"
          width="100%"
        /> */}

        <div style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}>
          <img
            src={alidayunit}
            className="img img-fluid"

          />
        </div>
        
        <h1 style={{

textAlign: "center",
}}>
<span style={{
    color: "#d4982b",
    fontFamily: "Futura Heavy",
}}>
    Ali</span>


<span style={{
    color: "#003366",
    fontFamily: "Futura Heavy",
}}>day</span>'s on a quick break<br></br><br></br>It'll be back next month from the  <span style={{
    color: "#d4982b",
    fontFamily: "Futura Heavy",
}}>10th - 14th</span><br></br><br></br>Stay tuned for some epic laughs and awesome deals</h1>


        {/* <div className="px-3">
          <div className="catalogsection mt-4">
            <div className="mt-3">
              <p className="text-center mb-0 alidaysubtitle">Price Drop Guarantee</p>
              <h1 className="text-center font-weight-bold alidaysecname">Explore Aliday Categories</h1>
            </div>
            <div className="emptyspacermid"></div>
            <div className="row mt-2">
              <div className="col-6">
                <Link to={{
                  pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                  search: "discount_sort=true&filter_by_category%5B%5D=191"
                }}>
                  <img
                    src={catalog1}
                    alt="category"
                    className="img img-fluid h-100"
                  />
                </Link>
              </div>

              <div className="col-6">
                <Link to={{
                  pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",

                  search: "discount_sort=true&filter_by_category%5B%5D=194"
                }}>
                  <img
                    src={catalog2}
                    alt="category"
                    className="img img-fluid h-100"
                  />
                </Link>
              </div>

            </div>
            <div className="row mt-2">
              <div className="col-6">
                <Link to={{
                  pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                  search: "discount_sort=true&filter_by_category%5B%5D=186"

                }}>
                  <img
                    src={catalog3}
                    alt="category"
                    className="img img-fluid h-100"
                  />
                </Link>
              </div>

              <div className="col-6">
                <Link to={{
                  pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                  search: "discount_sort=true&filter_by_category%5B%5D=193"
                }}>
                  <img
                    src={catalog4}
                    alt="category"
                    className="img img-fluid h-100"
                  />
                </Link>
              </div>

            </div>


          </div>
        </div>
        <Link to={{
          pathname: "/" + props.countryUrl +
            "-" + props.language + "/items/products",
          search: "filter_by_brand%5B%5D=513"
        }}>
          <img
            src={NeverWetMob}
            className="img img-fluid neverwet"
            width="100%"
          />
        </Link>
        <div className="px-3">

          <div className="section combosec">
            <div>
              <p className="text-center mb-0 alidaysubtitle">Limited Time</p>
              <h1 className="text-center font-weight-bold alidaysecname">Aliday Bundles🎁</h1>
              <p className="text-center mb-0 alidaysecdetails">Elevate your Aliday experience with our exclusive bundles section—perfect pairings, extraordinary savings.</p>
            </div>
            <div className="row mt-4">
              <div className="col-6 text-center oddcombo">
                <Link to="paint/rust-oleum-2x-ultra-cover-primer-spray-flat-white-rust-oleum-275660-never-wet-multi-purpose-kit-white-p-90629?sku=249058+275660">
                  <img
                    src={combo1}
                    className="img img-fluid"
                  />
                  <div className="combocontent">
                    <p className="my-0">
                      <Link className="category-link comboname">Combo Offer</Link>
                    </p>
                    <p className="mt-0 mb-1">
                      <Link className="category-link comboprice">AED 41</Link>
                    </p>
                  </div>
                </Link>
              </div>


              <div className="col-6 text-center evencombo">
                <Link to="paint/rust-oleum-stone-spray-paint-pebble-stone-rust-oleum-275619-never-wet-multi-purpose-kit-silver-gray-p-90631?sku=7995830+275619">
                  <img
                    src={combo2}
                    className="img img-fluid"
                  />
                  <div className="combocontent">
                    <p className="my-0">
                      <Link className="category-link comboname">Combo Offer</Link>
                    </p>
                    <p className="mt-0 mb-1">
                      <Link className="category-link comboprice">AED 41</Link>
                    </p>
                  </div>
                </Link>
              </div>

              <div className="col-6 text-center oddcombo top-space">
                <Link to="paint/rust-oleum-ultra-matte-paint-linen-white-rustoleum-never-wet-boot-shoe-spray-clear-p-90630?sku=302591+280886">

                  <img
                    src={combo3}
                    className="img img-fluid"
                  />
                  <div className="combocontent">
                    <p className="my-0">
                      <Link className="category-link comboname">Combo Offer</Link>
                    </p>
                    <p className="mt-0 mb-1">
                      <Link className="category-link comboprice">AED 41</Link>
                    </p>
                  </div>
                </Link>
              </div>


              <div className="col-6 text-center evencombo top-space">
                <Link to="paint/rust-oleum-high-heat-flat-black-rust-oleum-fabric-water-repelling-treatment-11oz-p-90628?sku=248903+278146">
                  <img
                    src={combo4}
                    className="img img-fluid"
                  />
                  <div className="combocontent">
                    <p className="my-0">
                      <Link className="category-link comboname">Combo Offer</Link>
                    </p>
                    <p className="mt-0 mb-1">
                      <Link className="category-link comboprice">AED 41</Link>
                    </p>
                  </div>
                </Link>
              </div>

            </div>
          </div>
        </div>

        <img
          src={BrandFavourite}
          className="img img-fluid"
          width="100%"
        />

        <div className="mt-4 px-3">
          <Swiper
            spaceBetween={10}
            slidesPerView={4} // or 'auto'
            slidesPerColumn={2}
            slidesPerColumnFill="row"
            pagination={false}
            navigation={true}
            className="brandfav"
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=63"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5tFSvZ45KEwSh8toU7PeA8.jpeg" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>

            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=383"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qt1o965TPpRLZLGXy4p1je.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >

              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=372"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8DzBfcT4xfJrK3P23vAUhE.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=168"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3PwLdMpgncAARimuFawCwK.jpeg" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=384"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/86i7XzSUcpmUFv1nD4sg78.png" className="img img-fluid sbb" width="100%" />
              </Link>


            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=124"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qpTEjuoDGv2bbLunoAua3H.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >

              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=73"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YvGp9qJBuUhj6nhbQX3ue.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=487"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/unATv96BjLDmE77JKTNQSf.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=178"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/tP1RDF6oiNm6i9hxUHXKYv.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}

            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "discount_sort=true&filter_by_brand%5B%5D=288"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/htuk69yLp7qyNdEFag6TPt.png" className="img img-fluid sbb" width="100%" />
              </Link>

            </SwiperSlide>

          </Swiper>

          <div className="mt-4">
            <div className="emptyspacer"></div>
            <div className="sbcatsection">
              <div>
                <h1 className="text-center font-weight-bold alidaysecname">Shop by Category</h1>
              </div>
              <div className="mt-4 row">
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 cat-img oddslide custom-p-right"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=489"
                  }}>
                    <img
                      src={one}
                      className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Vacuum Cleaners
                      </p>

                    </div>
                  </Link>
                </div>
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 cat-img evenslide custom-p-left"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=206&filter_by_category%5B%5D=291"
                  }}>
                    <img
                      src={two} className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Washers & Dryers
                      </p>

                    </div>
                  </Link>
                </div>
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 mt-3 cat-img evenslide  custom-p-right"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=214"
                  }}>
                    <img
                      src={three} className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Water Heaters
                      </p>

                    </div>
                  </Link>
                </div>
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 mt-3 cat-img oddslide custom-p-left"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=208"
                  }}>
                    <img
                      src={four} className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Microwaves & Ovens
                      </p>

                    </div>
                  </Link>
                </div>
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 mt-3 cat-img oddslide custom-p-right"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289"
                  }}>
                    <img
                      src={five}
                      className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Refrigerators
                      </p>

                    </div>
                  </Link>
                </div>

                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 mt-3 cat-img evenslide  custom-p-right"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=297"
                  }}>
                    <img
                      src={eight} className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Coffee Maker
                      </p>

                    </div>
                  </Link>
                </div>
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 mt-3 cat-img oddslide custom-p-left"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=300"
                  }}>
                    <img
                      src={nine} className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Toasters
                      </p>

                    </div>
                  </Link>
                </div>
                <div
                  onClick={(e) => e.preventDefault()}
                  key={1}
                  className="col-6 mt-3 cat-img evenslide custom-p-left"
                >
                  <Link to={{
                    pathname: "/" + props.countryUrl +
                      "-" + props.language + "/items/products",
                    search: "filter_by_category%5B%5D=295"
                  }}>
                    <img
                      src={seven} className="img img-fluid sbc"
                    />
                    <div className="combocontent">
                      <p className="my-1 comboname">
                        Juicers
                      </p>

                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to={{
          pathname: "/" + props.countryUrl +
            "-" + props.language + "/winter-sale"
        }}>
          <img
            src="https://alisuq.com/static/media/1440%20x%20300%20Backpacks%20Desktop.268297a5.jpg"
            className="my-4 img img-fluid"
            width="100%"
          />
        </Link>
        <div className="px-3">
          <Swiper
            spaceBetween={10}
            slidesPerView={4} // or 'auto'
            slidesPerColumn={2}
            slidesPerColumnFill="row"
            pagination={false}
            navigation={true}
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=500"
              }}>
                <img
                  src={CampingTentsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=507"
              }}>
                <img
                  src={SleepingBagsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=499"
              }}>
                <img
                  src={CampingFurnitureImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=503"
              }}>
                <img
                  src={CharcoalImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=505"
              }}>
                <img
                  src={FlashlightImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=508"
              }}>
                <img
                  src={WaterBottleImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=498"
              }}>
                <img
                  src={CampingBackpacksImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=501"
              }}>
                <img
                  src={CampingUtensilsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=510"
              }}>
                <img
                  src={CampingCoolersImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=496"
              }}>
                <img
                  src={BBQgrillsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=495"
              }}>
                <img
                  src={CampingToolsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=502"
              }}>
                <img
                  src={CapsCoolnetsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=497"
              }}>
                <img
                  src={BinocularsImg}
                  className="img img-fluid"
                  height="110px"
                />
              </Link>
            </SwiperSlide>
          </Swiper>

          <div className="sbcatsection">
            <div className="mt-5">
              <h1 className="text-center font-weight-bold alidaysecname">Explore Our Variety of Paints</h1>
            </div>
            <div className="emptyspacer"></div>

            <div className="row">
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 custom-p-right"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=423"
                }}>
                  <img
                    src={onep}
                    className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 custom-p-left"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=439"
                }}>
                  <img
                    src={twop} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-right"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=440"
                }}>
                  <img
                    src={threep} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-left"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=442"
                }}>
                  <img
                    src={fourp} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-right"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=438"
                }}>
                  <img
                    src={fivep}
                    className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-left"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=271"
                }}>
                  <img
                    src={sixp} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-right"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=424"
                }}>
                  <img
                    src={sevenp} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-left"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=445"
                }}>
                  <img
                    src={eightp} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-right"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=444"
                }}>
                  <img
                    src={ninep} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                key={1}
                className="col-6 mt-3 custom-p-left"
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_category%5B%5D=274"
                }}>
                  <img
                    src={tenp} className="img img-fluid paintcat"
                    height="150px"
                  />
                </Link>
              </div>
            </div>

            <div className="emptyspacer"></div>
          </div>
        </div>
        <Link to={{
          pathname: "/" + props.countryUrl +
            "-" + props.language + "/items/products",
          search: "filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"


        }}>
          <img
            src="https://alisuq.com/static/media/Paints-Primers.e069f360.jpg"
            className="img img-fluid mt-3"
            width="100%"
          />
        </Link>
        <div className="my-4 px-3">
          <div
            className="row"
          >
            <div
              onClick={(e) => e.preventDefault()}
              key={1}
              className="col-4"
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=42"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hSZ6J8vxQ8pENansr4opLk.png"
                  className="img img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </div>
            <div
              onClick={(e) => e.preventDefault()}
              key={1}
              className="col-4"
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=45"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8BBQmrEBYX1sD3jqfstdj6.png" className="img img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </div>
            <div
              onClick={(e) => e.preventDefault()}
              key={1}
              className="col-4"
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=48"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fwyPyYsDLWYNkLtUk8MS4E.png" className="img img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </div>
            <div
              onClick={(e) => e.preventDefault()}
              key={1}
              className="col-4 mt-2"
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=94"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/wuiWMpykzKEkkbizxXKKdW.png" className="img img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </div>
            <div
              onClick={(e) => e.preventDefault()}
              key={1}
              className="col-4 mt-2"
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=329"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/6wbn1oxKSWoA1wYVHjRgju.png"
                  className="img img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </div>
            <div
              onClick={(e) => e.preventDefault()}
              key={1}
              className="col-4 mt-2"
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=64"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jp81DcrwVpXgbTLFPj93Tw.png" className="img img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </div>
          </div>

        </div>



        <div className="mt-5 mypages-container">
          <div>
            <h1 className="text-center font-weight-bold alidaysecname">Categories We Love</h1>
          </div>
          <div className="row mt-3 catwelove">
            <div className="col-12">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=182"
              }}>
                <img
                  src={cat1}
                  alt="category"
                  className="img img-fluid h-100"
                />
              </Link>
            </div>
            <div className="col-12 mt-2">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=189"
              }}>
                <img
                  src={cat2}
                  alt="category"
                  className="img img-fluid h-100"
                />
              </Link>
            </div>
            <div className="col-12 mt-2">
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_category%5B%5D=184"
              }}>
                <img
                  src={cat3}
                  alt="category"
                  className="img img-fluid h-100"
                />
              </Link>
            </div>
          </div>
        </div> */}

      </div>
    </MobileLayoutRenderer>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};


export default connect(mapStateToProps, null)(AliDayMobPage);


