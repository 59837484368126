import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { apiJson } from "../../../Api";

import "../../../assets/styles/component/customer/checkout/checkout.scss";
import OrderItems from "./CheckoutOrderItems";
import OrderSummary from "./OrderSummary";
import PaymentMethod from "./PaymentMethod";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import ShippingFooter from "../shippingfooter/ShippingFooter";
import { set_loading } from "../../../redux/actions/LayoutAction";
import { set_addresses } from "../../../redux/actions/SelectAddressAction";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from "react-phone-input-2";
import { BsCircle, BsCheckCircle } from "react-icons/bs";

const en_translation = require("../../../assets/locales/en/translation.json");
const ar_translation = require("../../../assets/locales/ar/translation.json");

const Checkout = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState(false);
  const [wallet, setWallet] = useState(true);
  const [redirect, setRedirect] = useState(false);
  let country_code =
    props.country.toLowerCase() === "united arab emirates"
      ? "uae"
      : props.country.toLowerCase() === "saudi arabia"
        ? "ksa"
        : "egypt";
  let message = "";

  const [is_delivery, setIsDelivery] = React.useState(true);

  const [addressModalShow, setAddressModalShow] = React.useState(false);
  const [editAddressModalShow, setEditAddressModalShow] = React.useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [first_name, setFirstName] = useState("");
  const [first_name_err, setFirstNameError] = useState("");
  const [last_name, setLastName] = useState("-");
  const [contact, setContact] = useState("");
  const [contact_err, setContactError] = useState("");
  const [address1, setAddress1] = useState("");
  const [address1_err, setAddress1Error] = useState("");
  const [address2, setAddress2] = useState("");
  const [address2_err, setAddress2Error] = useState("");
  const [emirate, setEmirate] = useState("");
  const [emirate_err, setEmirateError] = useState("");
  const [address_is_default, setAddressIsDefault] = useState(false);
  const [addresses_modal_show, setAddressesModalShow] = useState(false);

  const [pickup_addresses, setPickupAddresses] = useState([]);
  const [customer_name, setCustomerName] = useState(props.user?.first_name_en ? props.user?.first_name_en + " " + props.user?.last_name_en : "");
  const [customer_contact, setCustomerContact] = useState("");

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.set_loading(true);

    apiJson
      .checkout.pickupAddresses(props.countryId)
      .then(response => {
        if (response.data) {
          setPickupAddresses(response.data);
        }
      })

    if (props.auth) {
      apiJson.select.getAddress().then((response) => {
        props.set_loading(false);

        if (response.success) {
          props.set_addresses({
            address: response.data,
          });
        } else if (!response.status) {
          // eslint-disable-next-line
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

      setIsDelivery(props.createdAddress?.is_delivery == 1 ? true : false);

      apiJson.cart.getCartItems(props.countryId).then((response) => {
        props.set_loading(false);

        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
            sub_total: props.createdAddress ? props.createdAddress.sub_total : response.data.sub_total,
            discount: props.createdAddress ? props.createdAddress.discount : response.data.discount,
            delivery_fee: props.createdAddress ? props.createdAddress.delivery_fee : "0",
            vat: props.createdAddress ? props.createdAddress.vat : response.data.vat,
            grand_total: props.createdAddress ? props.createdAddress.grand_total : response.data.sub_total,
          });
          setData(true);
        } else if (!response.status) {
          setData(true);
          message = response.message // eslint-disable-line react-hooks/exhaustive-deps
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    } else {
      if (localStorage.getItem("guest_user_address")) {
        // props.set_addresses({
        //   address: [
        //     JSON.parse(localStorage.getItem("guest_user_address"))
        //   ],
        // });

        var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
        guest_user_address.default = true;

        props.set_addresses({
          address: [guest_user_address]
        });

        const request = {
          order: {
            address_id: guest_user_address.id,
            is_delivery: 1
          },
        };
        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((resp) => {
            if (resp.success) {
              setIsDelivery(resp.data.is_delivery == 1 ? true : false);
              props.set_cart_items({
                order: {
                  address: guest_user_address,
                  id: resp.data.id,
                  createdAddress: resp.data,
                },
              });
              apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                props.set_loading(false);
                if (cart_response.success) {
                  props.set_cart_items({
                    cart_items: cart_response.data,
                    sub_total: resp.data.sub_total,
                    discount: resp.data.discount,
                    delivery_fee: resp.data.delivery_fee,
                    vat: resp.data.vat,
                    grand_total: resp.data.grand_total,
                  });
                  setData(true);
                } else if (!cart_response.status) {
                  setData(true);
                  message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  setData(true);
                  if (cart_response.status >= 400 && cart_response.status < 500) {
                    message = cart_response.message
                      ? cart_response.message
                      : cart_response.errors
                        ? cart_response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = cart_response.message
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
                setData(true);
              });
            } else if (!resp.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (resp.status >= 400 && resp.status < 500) {
                message = resp.message
                  ? resp.message
                  : resp.errors
                    ? resp.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = resp.message
                  ? resp.message !== ""
                    ? resp.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });

        apiJson.cart.getCartItems(props.countryId).then((response) => {
          props.set_loading(false);

          if (response.success) {
            props.set_cart_items({
              cart_items: response.data,
              sub_total: props.createdAddress ? props.createdAddress.sub_total : response.data.sub_total,
              discount: props.createdAddress ? props.createdAddress.discount : response.data.discount,
              delivery_fee: props.createdAddress ? props.createdAddress.delivery_fee : "0",
              vat: props.createdAddress ? props.createdAddress.vat : response.data.vat,
              grand_total: props.createdAddress ? props.createdAddress.grand_total : response.data.sub_total,
            });
            setData(true);
          } else if (!response.status) {
            setData(true);
            message = response.message // eslint-disable-line react-hooks/exhaustive-deps
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setData(true);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      }
      else {
        props.set_addresses({
          address: [],
        });
        // setIsDelivery(props.createdAddress?.is_delivery == 1 ? true : false);
        setIsDelivery(true);

        apiJson.cart.getCartItems(props.countryId).then((response) => {
          props.set_loading(false);

          if (response.success) {
            props.set_cart_items({
              cart_items: response.data,
              sub_total: props.createdAddress ? props.createdAddress.sub_total : response.data.sub_total,
              discount: props.createdAddress ? props.createdAddress.discount : response.data.discount,
              delivery_fee: props.createdAddress ? props.createdAddress.delivery_fee : "0",
              vat: props.createdAddress ? props.createdAddress.vat : response.data.vat,
              grand_total: props.createdAddress ? props.createdAddress.grand_total : response.data.sub_total,
            });
            setData(true);
          } else if (!response.status) {
            setData(true);
            message = response.message // eslint-disable-line react-hooks/exhaustive-deps
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setData(true);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    setCustomerContact(props.address?.length ?
      props.address.filter(address => address.default == true).length ?
        props.address.filter(address => address.default == true)[0] ?
          props.address.filter(address => address.default == true)[0].contact_number
          :
          ""
        :
        ""
      :
      "")
  }, [props.address]);

  const getDraftData = () => {
    if (props.id) {
      apiJson.checkout
        .updatedDraft(props.id, props.countryId)
        .then((response) => {
          if (response.success) {
            props.set_cart_items({
              sub_total: response.data.sub_total,
              discount: response.data.discount,
              delivery_fee: response.data.delivery_fee,
              vat: response.data.vat,
              grand_total: response.data.grand_total,
              order: {
                address: props.address,
                id: props.id,
                createdAddress: {
                  ...props.createdAddress,
                  sub_total: response.data.sub_total,
                  discount: response.data.discount,
                  delivery_fee: response.data.delivery_fee,
                  vat: response.data.vat,
                  grand_total: response.data.grand_total,
                },
              },
            });
            setData(true);
          } else if (!response.status) {
            setData(true);
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          } else {
            setData(true);
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
    else {
      apiJson.cart.getCartItems(props.countryId).then((response) => {
        props.set_loading(false);

        if (response.success) {
          props.set_cart_items({
            cart_items: response.data,
            sub_total: props.createdAddress ? props.createdAddress.sub_total : response.data.sub_total,
            discount: props.createdAddress ? props.createdAddress.discount : response.data.discount,
            delivery_fee: props.createdAddress ? props.createdAddress.delivery_fee : "0",
            vat: props.createdAddress ? props.createdAddress.vat : response.data.vat,
            grand_total: props.createdAddress ? props.createdAddress.grand_total : response.data.sub_total,
          });
          setData(true);
        } else if (!response.status) {
          setData(true);
          message = response.message // eslint-disable-line react-hooks/exhaustive-deps
            ? response.message !== ""
              ? response.message
              : t("errors.wrong")
            : t("errors.wrong");
          showError();
        } else {
          setData(true);
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
  };
  useEffect(
    () => {
      props.cart_items?.line_items?.length === 0 && setRedirect(true)
    },
    [props.cart_items?.line_items?.length]
  );

  const setDefaultAddress = (id) => {
    if (localStorage.getItem("access_token")) {
      props.set_loading(true);
      setData(false);
      apiJson.select.setDefault(id).then((response) => {
        if (response.success) {
          props.set_addresses({
            address: props.address.map((item) =>
              item.id === response.data.id
                ? { ...item, default: true }
                : item.default
                  ? { ...item, default: false }
                  : item
            ),
          });
          const request = {
            order: {
              address_id: response.data.id,
              is_delivery: 1
            },
          };
          apiJson.select
            .varifyAdress(request, props.countryId)
            .then((resp) => {
              if (resp.success) {
                message = "Address changed successfully.";
                showSuccess();
                setAddressesModalShow(false);
                props.set_cart_items({
                  order: {
                    address: response.data,
                    id: resp.data.id,
                    createdAddress: resp.data,
                  },
                });
                apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                  props.set_loading(false);
                  if (cart_response.success) {
                    props.set_cart_items({
                      cart_items: cart_response.data,
                      sub_total: resp.data.sub_total,
                      discount: resp.data.discount,
                      delivery_fee: resp.data.delivery_fee,
                      vat: resp.data.vat,
                      grand_total: resp.data.grand_total,
                    });
                    setData(true);
                  } else if (!cart_response.status) {
                    setData(true);
                    message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  } else {
                    setData(true);
                    if (cart_response.status >= 400 && cart_response.status < 500) {
                      message = cart_response.message
                        ? cart_response.message
                        : cart_response.errors
                          ? cart_response.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = cart_response.message
                        ? cart_response.message !== ""
                          ? cart_response.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                  setData(true);
                });
              } else if (!resp.status) {
                message = t("errors.wrong");
                showError();
              } else {
                if (resp.status >= 400 && resp.status < 500) {
                  message = resp.message
                    ? resp.message
                    : resp.errors
                      ? resp.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = resp.message
                    ? resp.message !== ""
                      ? resp.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
            });
        } else if (!response.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (response.status >= 400 && response.status < 500) {
            message = response.message
              ? response.message
              : response.errors
                ? response.errors
                : t("errors.wrong");
            showError();
          } else {
            message = response.message
              ? response.message !== ""
                ? response.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
    }
    else {
      if (localStorage.getItem("guest_user_address")) {
        var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));

        props.set_addresses({
          address: props.address.map((item) =>
            item.id === guest_user_address.id
              ? { ...item, default: true }
              : item.default
                ? { ...item, default: false }
                : item
          ),
        });

        const request = {
          order: {
            address_id: guest_user_address.id,
            is_delivery: 1
          },
        };
        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((resp) => {
            if (resp.success) {
              props.set_cart_items({
                order: {
                  address: guest_user_address,
                  id: resp.data.id,
                  createdAddress: resp.data,
                },
              });
              apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                props.set_loading(false);
                if (cart_response.success) {
                  message = "Address changed successfully.";
                  showSuccess();
                  setAddressesModalShow(false);
                  props.set_cart_items({
                    cart_items: cart_response.data,
                    sub_total: resp.data.sub_total,
                    discount: resp.data.discount,
                    delivery_fee: resp.data.delivery_fee,
                    vat: resp.data.vat,
                    grand_total: resp.data.grand_total,
                  });
                  setData(true);
                } else if (!cart_response.status) {
                  setData(true);
                  message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  setData(true);
                  if (cart_response.status >= 400 && cart_response.status < 500) {
                    message = cart_response.message
                      ? cart_response.message
                      : cart_response.errors
                        ? cart_response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = cart_response.message
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
                setData(true);
              });
            } else if (!resp.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (resp.status >= 400 && resp.status < 500) {
                message = resp.message
                  ? resp.message
                  : resp.errors
                    ? resp.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = resp.message
                  ? resp.message !== ""
                    ? resp.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      }
    }
  }

  const onAddAddressClicked = () => {
    setProductToEdit(null);
    setFirstName("");
    setContact("");
    setAddress1("");
    setAddress2("");
    setEmirate("");
    setAddressesModalShow(false);
    setAddressModalShow(true);
  }

  const onAddressInputFieldChanged = (e) => {
    if (e.target.name == "first_name") {
      setFirstName(e.target.value);
    }
    else if (e.target.name == "address1") {
      setAddress1(e.target.value);
    }
    else if (e.target.name == "address2") {
      setAddress2(e.target.value);
    }
    else if (e.target.name == "emirate") {
      setEmirate(e.target.value);
    }
  }

  const handleValidation = () => {
    var isValid = true;

    if (!first_name) {
      setFirstNameError("*Full name is required.");
      isValid = false;
    }
    else {
      setFirstName("");
    }

    if (!contact) {
      setContactError("*Contact is required.");
      isValid = false;
    }
    else {
      setContactError("");
    }

    if (!address1) {
      setAddress1Error("*Building name/no. is required.");
      isValid = false;
    }
    else {
      setAddress1Error("");
    }

    if (!address2) {
      setAddress2Error("*Flat/Apartment or Villa no. is required.");
      isValid = false;
    }
    else {
      setAddress2Error("");
    }

    if (!emirate) {
      setEmirateError("*Emirate is required.");
      isValid = false;
    }
    else {
      setEmirateError("");
    }

    return isValid;
  }

  const saveAddressBtnClicked = () => {
    if (handleValidation()) {
      let tempLabel = "Home";

      apiJson.select
        .addAdress({
          address: {
            country:
              props.countryId == 1
                ? "uae"
                : props.countryId == 2
                  ? "ksa"
                  : "egypt",
            state: emirate,
            address1: address1,
            address2: address2,
            latitude: "-",
            longitude: "-",
            label: tempLabel,
            first_name: first_name,
            last_name: last_name,
            contact_number: "+" + contact,
          },
        })
        .then((response) => {
          if (response.success) {
            props.set_loading(true);

            if (props.auth) {
              apiJson.select.setDefault(response.data.id).then((response) => {
                if (response.success) {
                  // props.set_addresses({
                  //   address: props.address.map((item) =>
                  //     item.id === response.data.id
                  //       ? { ...item, default: true }
                  //       : item.default
                  //         ? { ...item, default: false }
                  //         : item
                  //   ),
                  // });
                  const request = {
                    order: {
                      address_id: response.data.id,
                      is_delivery: 1
                    },
                  };
                  apiJson.select
                    .varifyAdress(request, props.countryId)
                    .then((resp) => {
                      if (resp.success) {
                        props.set_cart_items({
                          order: {
                            address: response.data,
                            id: resp.data.id,
                            createdAddress: resp.data,
                          },
                        });
                        apiJson.select.getAddress().then((response) => {
                          props.set_loading(false);

                          if (response.success) {
                            props.set_addresses({
                              address: response.data,
                            });
                            setAddressModalShow(false);
                          } else if (!response.status) {
                            // eslint-disable-next-line
                            message = t("errors.wrong");
                            showError();
                          } else {
                            if (response.status >= 400 && response.status < 500) {
                              message = response.message
                                ? response.message
                                : response.errors
                                  ? response.errors
                                  : t("errors.wrong");
                              showError();
                            } else {
                              message = response.message
                                ? response.message !== ""
                                  ? response.message
                                  : t("errors.wrong")
                                : t("errors.wrong");
                              showError();
                            }
                          }
                          props.set_loading(false);
                        });
                        apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                          props.set_loading(false);
                          if (cart_response.success) {
                            props.set_cart_items({
                              cart_items: cart_response.data,
                              sub_total: resp.data.sub_total,
                              discount: resp.data.discount,
                              delivery_fee: resp.data.delivery_fee,
                              vat: resp.data.vat,
                              grand_total: resp.data.grand_total,
                            });
                            setData(true);
                          } else if (!cart_response.status) {
                            setData(true);
                            message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          } else {
                            setData(true);
                            if (cart_response.status >= 400 && cart_response.status < 500) {
                              message = cart_response.message
                                ? cart_response.message
                                : cart_response.errors
                                  ? cart_response.errors
                                  : t("errors.wrong");
                              showError();
                            } else {
                              message = cart_response.message
                                ? cart_response.message !== ""
                                  ? cart_response.message
                                  : t("errors.wrong")
                                : t("errors.wrong");
                              showError();
                            }
                          }
                          setData(true);
                        });
                      } else if (!resp.status) {
                        message = t("errors.wrong");
                        showError();
                      } else {
                        if (resp.status >= 400 && resp.status < 500) {
                          message = resp.message
                            ? resp.message
                            : resp.errors
                              ? resp.errors
                              : t("errors.wrong");
                          showError();
                        } else {
                          message = resp.message
                            ? resp.message !== ""
                              ? resp.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        }
                      }
                    });
                } else if (!response.status) {
                  message = t("errors.wrong");
                  showError();
                } else {
                  if (response.status >= 400 && response.status < 500) {
                    message = response.message
                      ? response.message
                      : response.errors
                        ? response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = response.message
                      ? response.message !== ""
                        ? response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
            } else {
              localStorage.setItem("guest_user_address", JSON.stringify(response.data));

              if (localStorage.getItem("guest_user_address")) {
                var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
                guest_user_address.default = true;

                props.set_addresses({
                  address: [guest_user_address]
                });

                const request = {
                  order: {
                    address_id: guest_user_address.id,
                    is_delivery: 1
                  },
                };
                apiJson.select
                  .varifyAdress(request, props.countryId)
                  .then((resp) => {
                    if (resp.success) {
                      props.set_cart_items({
                        order: {
                          address: guest_user_address,
                          id: resp.data.id,
                          createdAddress: resp.data,
                        },
                      });
                      apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                        props.set_loading(false);
                        if (cart_response.success) {
                          props.set_cart_items({
                            cart_items: cart_response.data,
                            sub_total: resp.data.sub_total,
                            discount: resp.data.discount,
                            delivery_fee: resp.data.delivery_fee,
                            vat: resp.data.vat,
                            grand_total: resp.data.grand_total,
                          });
                          setData(true);
                        } else if (!cart_response.status) {
                          setData(true);
                          message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                            ? cart_response.message !== ""
                              ? cart_response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        } else {
                          setData(true);
                          if (cart_response.status >= 400 && cart_response.status < 500) {
                            message = cart_response.message
                              ? cart_response.message
                              : cart_response.errors
                                ? cart_response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = cart_response.message
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        setData(true);
                      });
                    } else if (!resp.status) {
                      message = t("errors.wrong");
                      showError();
                    } else {
                      if (resp.status >= 400 && resp.status < 500) {
                        message = resp.message
                          ? resp.message
                          : resp.errors
                            ? resp.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = resp.message
                          ? resp.message !== ""
                            ? resp.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              }
              else {
                props.set_addresses({
                  address: [],
                });
              }
              setAddressModalShow(false);
            }
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
  }

  const onEditAddressClicked = (address) => {
    setAddressesModalShow(false);
    setAddressIsDefault(address.default);
    setProductToEdit(address);
    setFirstName(address.first_name);
    setContact(address.contact_number);
    setAddress1(address.address1);
    setAddress2(address.address2);
    setEmirate(address.state);
    setEditAddressModalShow(true);
  }

  const onEditAdressClosed = () => {
    setProductToEdit(null);
    setFirstName("");
    setContact("");
    setAddress1("");
    setAddress2("");
    setEmirate("");
    setEditAddressModalShow(false);
  }

  const editAddressBtnClicked = () => {
    if (handleValidation()) {
      let tempLabel = "Home";

      apiJson.select
        .updateAddress(productToEdit.id, {
          address: {
            country:
              props.countryId == 1
                ? "uae"
                : props.countryId == 2
                  ? "ksa"
                  : "egypt",
            state: emirate,
            address1: address1,
            address2: address2,
            latitude: "-",
            longitude: "-",
            label: tempLabel,
            first_name: first_name,
            last_name: last_name,
            contact_number: "+" + contact.replaceAll("+", "")
          },
        })
        .then((response) => {
          if (response.success) {
            props.set_loading(true);

            if (props.auth) {
              if (address_is_default) {
                const request = {
                  order: {
                    address_id: response.data.id,
                    is_delivery: 1
                  },
                };
                apiJson.select
                  .varifyAdress(request, props.countryId)
                  .then((resp) => {
                    if (resp.success) {
                      props.set_cart_items({
                        order: {
                          address: response.data,
                          id: resp.data.id,
                          createdAddress: resp.data,
                        },
                      });
                      apiJson.select.getAddress().then((response) => {
                        props.set_loading(false);

                        if (response.success) {
                          props.set_addresses({
                            address: response.data,
                          });
                          setEditAddressModalShow(false);
                        } else if (!response.status) {
                          // eslint-disable-next-line
                          message = t("errors.wrong");
                          showError();
                        } else {
                          if (response.status >= 400 && response.status < 500) {
                            message = response.message
                              ? response.message
                              : response.errors
                                ? response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = response.message
                              ? response.message !== ""
                                ? response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        props.set_loading(false);
                      });
                      apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                        props.set_loading(false);
                        if (cart_response.success) {
                          props.set_cart_items({
                            cart_items: cart_response.data,
                            sub_total: resp.data.sub_total,
                            discount: resp.data.discount,
                            delivery_fee: resp.data.delivery_fee,
                            vat: resp.data.vat,
                            grand_total: resp.data.grand_total,
                          });
                          setData(true);
                        } else if (!cart_response.status) {
                          setData(true);
                          message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                            ? cart_response.message !== ""
                              ? cart_response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        } else {
                          setData(true);
                          if (cart_response.status >= 400 && cart_response.status < 500) {
                            message = cart_response.message
                              ? cart_response.message
                              : cart_response.errors
                                ? cart_response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = cart_response.message
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        setData(true);
                      });
                    } else if (!resp.status) {
                      message = t("errors.wrong");
                      showError();
                    } else {
                      if (resp.status >= 400 && resp.status < 500) {
                        message = resp.message
                          ? resp.message
                          : resp.errors
                            ? resp.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = resp.message
                          ? resp.message !== ""
                            ? resp.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              }
              else {
                apiJson.select.getAddress().then((response) => {
                  props.set_loading(false);

                  if (response.success) {
                    props.set_addresses({
                      address: response.data,
                    });
                    setEditAddressModalShow(false);
                  } else if (!response.status) {
                    // eslint-disable-next-line
                    message = t("errors.wrong");
                    showError();
                  } else {
                    if (response.status >= 400 && response.status < 500) {
                      message = response.message
                        ? response.message
                        : response.errors
                          ? response.errors
                          : t("errors.wrong");
                      showError();
                    } else {
                      message = response.message
                        ? response.message !== ""
                          ? response.message
                          : t("errors.wrong")
                        : t("errors.wrong");
                      showError();
                    }
                  }
                  props.set_loading(false);
                });
              }
            } else {
              localStorage.setItem("guest_user_address", JSON.stringify(response.data));

              if (localStorage.getItem("guest_user_address")) {
                var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));

                props.set_addresses({
                  address: [guest_user_address]
                });

                const request = {
                  order: {
                    address_id: guest_user_address.id,
                    is_delivery: 1
                  },
                };
                apiJson.select
                  .varifyAdress(request, props.countryId)
                  .then((resp) => {
                    if (resp.success) {
                      props.set_cart_items({
                        order: {
                          address: guest_user_address,
                          id: resp.data.id,
                          createdAddress: resp.data,
                        },
                      });
                      apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                        props.set_loading(false);
                        if (cart_response.success) {
                          props.set_cart_items({
                            cart_items: cart_response.data,
                            sub_total: resp.data.sub_total,
                            discount: resp.data.discount,
                            delivery_fee: resp.data.delivery_fee,
                            vat: resp.data.vat,
                            grand_total: resp.data.grand_total,
                          });
                          setData(true);
                        } else if (!cart_response.status) {
                          setData(true);
                          message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                            ? cart_response.message !== ""
                              ? cart_response.message
                              : t("errors.wrong")
                            : t("errors.wrong");
                          showError();
                        } else {
                          setData(true);
                          if (cart_response.status >= 400 && cart_response.status < 500) {
                            message = cart_response.message
                              ? cart_response.message
                              : cart_response.errors
                                ? cart_response.errors
                                : t("errors.wrong");
                            showError();
                          } else {
                            message = cart_response.message
                              ? cart_response.message !== ""
                                ? cart_response.message
                                : t("errors.wrong")
                              : t("errors.wrong");
                            showError();
                          }
                        }
                        setData(true);
                      });
                    } else if (!resp.status) {
                      message = t("errors.wrong");
                      showError();
                    } else {
                      if (resp.status >= 400 && resp.status < 500) {
                        message = resp.message
                          ? resp.message
                          : resp.errors
                            ? resp.errors
                            : t("errors.wrong");
                        showError();
                      } else {
                        message = resp.message
                          ? resp.message !== ""
                            ? resp.message
                            : t("errors.wrong")
                          : t("errors.wrong");
                        showError();
                      }
                    }
                  });
              }
              else {
                props.set_addresses({
                  address: [],
                });
              }
              setEditAddressModalShow(false);
            }
          } else if (!response.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (response.status >= 400 && response.status < 500) {
              message = response.message
                ? response.message
                : response.errors
                  ? response.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = response.message
                ? response.message !== ""
                  ? response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
  }

  const onChangeAddressesClicked = () => {
    setAddressesModalShow(true);
  }

  const onPickupAddressSelected = (id) => {
    const request = {
      order: {
        pickup_address_id: id,
        is_delivery: 0
      },
    };
    apiJson.select
      .varifyAdress(request, props.countryId)
      .then((resp) => {
        if (resp.success) {
          props.set_cart_items({
            order: {
              address: null,
              id: resp.data.id,
              createdAddress: resp.data,
            },
          });

          apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
            props.set_loading(false);
            if (cart_response.success) {
              props.set_cart_items({
                cart_items: cart_response.data,
                sub_total: resp.data.sub_total,
                discount: resp.data.discount,
                delivery_fee: resp.data.delivery_fee,
                vat: resp.data.vat,
                grand_total: resp.data.grand_total,
              });
              setData(true);
            } else if (!cart_response.status) {
              setData(true);
              message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                ? cart_response.message !== ""
                  ? cart_response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            } else {
              setData(true);
              if (cart_response.status >= 400 && cart_response.status < 500) {
                message = cart_response.message
                  ? cart_response.message
                  : cart_response.errors
                    ? cart_response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = cart_response.message
                  ? cart_response.message !== ""
                    ? cart_response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
            setData(true);
          });
        } else if (!resp.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (resp.status >= 400 && resp.status < 500) {
            message = resp.message
              ? resp.message
              : resp.errors
                ? resp.errors
                : t("errors.wrong");
            showError();
          } else {
            message = resp.message
              ? resp.message !== ""
                ? resp.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });
  }

  const onHomeDeliveryClicked = (e) => {
    e.preventDefault();

    if (localStorage.getItem("guest_user_address")) {
      var guest_user_address = JSON.parse(localStorage.getItem("guest_user_address"));
      guest_user_address.default = true;

      props.set_addresses({
        address: [guest_user_address]
      });
      const request = {
        order: {
          address_id: guest_user_address.id,
          is_delivery: 1
        },
      };
      apiJson.select
        .varifyAdress(request, props.countryId)
        .then((resp) => {
          if (resp.success) {
            setIsDelivery(resp.data.is_delivery == 1 ? true : false);
            props.set_cart_items({
              order: {
                address: guest_user_address,
                id: resp.data.id,
                createdAddress: resp.data,
              },
            });
            apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
              if (cart_response.success) {
                props.set_cart_items({
                  cart_items: cart_response.data,
                  sub_total: resp.data.sub_total,
                  discount: resp.data.discount,
                  delivery_fee: resp.data.delivery_fee,
                  vat: resp.data.vat,
                  grand_total: resp.data.grand_total,
                });
              } else if (!cart_response.status) {
                message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                  ? cart_response.message !== ""
                    ? cart_response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              } else {
                setData(true);
                if (cart_response.status >= 400 && cart_response.status < 500) {
                  message = cart_response.message
                    ? cart_response.message
                    : cart_response.errors
                      ? cart_response.errors
                      : t("errors.wrong");
                  showError();
                } else {
                  message = cart_response.message
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                }
              }
              setData(true);
            });
          } else if (!resp.status) {
            message = t("errors.wrong");
            showError();
          } else {
            if (resp.status >= 400 && resp.status < 500) {
              message = resp.message
                ? resp.message
                : resp.errors
                  ? resp.errors
                  : t("errors.wrong");
              showError();
            } else {
              message = resp.message
                ? resp.message !== ""
                  ? resp.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            }
          }
        });
    }
    else {
      var defaultAddress = props.address?.filter(address => address.default == true);

      if (defaultAddress.length) {
        const request = {
          order: {
            address_id: defaultAddress[0]?.id,
            is_delivery: 1
          },
        };

        apiJson.select
          .varifyAdress(request, props.countryId)
          .then((resp) => {
            if (resp.success) {
              props.set_cart_items({
                order: {
                  address: defaultAddress[0],
                  id: resp.data.id,
                  createdAddress: resp.data,
                },
              });
              apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
                if (cart_response.success) {
                  props.set_cart_items({
                    cart_items: cart_response.data,
                    sub_total: resp.data.sub_total,
                    discount: resp.data.discount,
                    delivery_fee: resp.data.delivery_fee,
                    vat: resp.data.vat,
                    grand_total: resp.data.grand_total,
                  });
                } else if (!cart_response.status) {
                  message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                    ? cart_response.message !== ""
                      ? cart_response.message
                      : t("errors.wrong")
                    : t("errors.wrong");
                  showError();
                } else {
                  if (cart_response.status >= 400 && cart_response.status < 500) {
                    message = cart_response.message
                      ? cart_response.message
                      : cart_response.errors
                        ? cart_response.errors
                        : t("errors.wrong");
                    showError();
                  } else {
                    message = cart_response.message
                      ? cart_response.message !== ""
                        ? cart_response.message
                        : t("errors.wrong")
                      : t("errors.wrong");
                    showError();
                  }
                }
              });
            } else if (!resp.status) {
              message = t("errors.wrong");
              showError();
            } else {
              if (resp.status >= 400 && resp.status < 500) {
                message = resp.message
                  ? resp.message
                  : resp.errors
                    ? resp.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = resp.message
                  ? resp.message !== ""
                    ? resp.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
          });
      }
    }

    setIsDelivery(true);
  }

  const onClickCollectClicked = (e) => {
    e.preventDefault();

    const request = {
      order: {
        pickup_address_id: pickup_addresses[0]?.id,
        is_delivery: 0
      },
    };
    apiJson.select
      .varifyAdress(request, props.countryId)
      .then((resp) => {
        if (resp.success) {
          props.set_cart_items({
            order: {
              address: null,
              id: resp.data.id,
              createdAddress: resp.data,
            },
          });

          apiJson.cart.getCartItems(props.countryId).then((cart_response) => {
            props.set_loading(false);
            if (cart_response.success) {
              props.set_cart_items({
                cart_items: cart_response.data,
                sub_total: resp.data.sub_total,
                discount: resp.data.discount,
                delivery_fee: resp.data.delivery_fee,
                vat: resp.data.vat,
                grand_total: resp.data.grand_total,
              });
              setData(true);
            } else if (!cart_response.status) {
              setData(true);
              message = cart_response.message // eslint-disable-line react-hooks/exhaustive-deps
                ? cart_response.message !== ""
                  ? cart_response.message
                  : t("errors.wrong")
                : t("errors.wrong");
              showError();
            } else {
              setData(true);
              if (cart_response.status >= 400 && cart_response.status < 500) {
                message = cart_response.message
                  ? cart_response.message
                  : cart_response.errors
                    ? cart_response.errors
                    : t("errors.wrong");
                showError();
              } else {
                message = cart_response.message
                  ? cart_response.message !== ""
                    ? cart_response.message
                    : t("errors.wrong")
                  : t("errors.wrong");
                showError();
              }
            }
            setData(true);
          });
        } else if (!resp.status) {
          message = t("errors.wrong");
          showError();
        } else {
          if (resp.status >= 400 && resp.status < 500) {
            message = resp.message
              ? resp.message
              : resp.errors
                ? resp.errors
                : t("errors.wrong");
            showError();
          } else {
            message = resp.message
              ? resp.message !== ""
                ? resp.message
                : t("errors.wrong")
              : t("errors.wrong");
            showError();
          }
        }
      });

    setIsDelivery(false);
  }

  return (
    <Fragment>
      <Container className="customer-checkout-desktop" fluid>
        {!data ? (
          <div className="spinner-style"></div>
        ) : (
          <>
            <Row
              className="first-row nogutters"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Col md={12} lg={7} className="payment-col ml-auto">

                <Modal
                  show={addresses_modal_show}
                  onHide={() => setAddressesModalShow(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="customer-checkout-desktop"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      <p className="m-0 ship-to-heading">Ship To</p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-3 py-2 addresses-popup">
                    {props.address.map(
                      (address) =>
                        (address.country.toLowerCase() ===
                          props.country.toLowerCase() ||
                          address.country.toLowerCase() === country_code) && !address.default && (
                          <div
                            className="d-flex bg-white address-card py-2 justify-content-between align-items-center"
                            key={address.id}
                          >
                            <div
                              onClick={() => setDefaultAddress(address.id)}
                              className="d-flex align-items-center w-100">
                              <div>
                                <p className="my-0 font-weight-bold user_name">{address.first_name}</p>
                                <p className="my-0 info">{address.address1} - {address.address2}</p>
                                <p className="my-0 info">Phone Number:&nbsp;{address.contact_number}</p>
                              </div>
                            </div>
                            {
                              localStorage.getItem("guest_user_address") ?
                                <></>
                                // <div
                                //   onClick={() => onEditAddressClicked(address)}
                                //   className="view-edit-btn">
                                //   <p className="m-0">View / Edit</p>
                                // </div>
                                :
                                <div
                                  onClick={() => onEditAddressClicked(address)}
                                  className="view-edit-btn">
                                  <p className="m-0">View / Edit</p>
                                </div>
                            }
                          </div>
                        )
                    )}
                  </Modal.Body>
                  <Modal.Footer
                    onClick={onAddAddressClicked}
                    style={{
                      cursor: "pointer"
                    }}
                    className="justify-content-start">
                    <p className="m-0">{t("select-shipping-address.add-new-address")}</p>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={addressModalShow}
                  onHide={() => setAddressModalShow(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="add-address-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      ADD ADDRESS
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-3 py-2">
                    <div className="form-group">
                      <label className="mb-0">Full Name:</label>
                      <input name="first_name" type="text" className="form-control" placeholder="Full Name"
                        onChange={onAddressInputFieldChanged} value={first_name} />
                      <p className="m-0 text-danger">{first_name_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Contact:</label>
                      <PhoneInput
                        inputProps={{ name: "", maxLength: 17 }}
                        placeholder={"Mobile Number"}
                        countryCodeEditable={false}
                        onlyCountries={["ae"]}
                        country={"ae"}
                        value={contact}
                        // onBlur={() => setFieldTouched("phone", true)}
                        onChange={(val) => setContact(val)}
                        enableLongNumbers={false}
                        className="w-100"
                      />
                      <p className="m-0 text-danger">{contact_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Building name/no.:</label>
                      <input name="address1" type="text" className="form-control" placeholder="Building Name/No."
                        onChange={onAddressInputFieldChanged} value={address1} />
                      <p className="m-0 text-danger">{address1_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Floor, Apartment, Villa no.:</label>
                      <input name="address2" type="text" className="form-control" placeholder="Floor, Apartment, Villa no."
                        onChange={onAddressInputFieldChanged} value={address2} />
                      <p className="m-0 text-danger">{address2_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Emirate:</label>
                      <select
                        name="emirate"
                        className="w-100 form-control"
                        onChange={onAddressInputFieldChanged}
                        value={emirate}
                      >
                        <option value="">--- Select emirate ---</option>
                        {
                          props.countryId == 1 ?
                            <>
                              <option value="Dubai">Dubai</option>
                              <option value="Abu Dhabi">Abu Dhabi</option>
                              <option value="Sharjah">Sharjah</option>
                              <option value="Ajman">Ajman</option>
                              <option value="Umm Al Quwain">Umm Al Quwain</option>
                              <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                              <option value="Fujairah">Fujairah</option>
                            </>
                            :
                            <></>
                        }
                      </select>
                      <p className="m-0 text-danger">{emirate_err}</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={saveAddressBtnClicked}
                      className="save-address">Save Address</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={editAddressModalShow}
                  onHide={onEditAdressClosed}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="add-address-modal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      EDIT ADDRESS
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-3 py-2">
                    <div className="form-group">
                      <label className="mb-0">Full Name:</label>
                      <input name="first_name" type="text" className="form-control" placeholder="Full Name"
                        onChange={onAddressInputFieldChanged} value={first_name} />
                      <p className="m-0 text-danger">{first_name_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Contact:</label>
                      <PhoneInput
                        inputProps={{ name: "", maxLength: 17 }}
                        placeholder={"Mobile Number"}
                        countryCodeEditable={false}
                        onlyCountries={["ae"]}
                        country={"ae"}
                        value={contact}
                        // onBlur={() => setFieldTouched("phone", true)}
                        onChange={(val) => setContact(val)}
                        enableLongNumbers={false}
                        className="w-100"
                      />
                      <p className="m-0 text-danger">{contact_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Building name/no.:</label>
                      <input name="address1" type="text" className="form-control" placeholder="Building Name/No."
                        onChange={onAddressInputFieldChanged} value={address1} />
                      <p className="m-0 text-danger">{address1_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Floor, Apartment, Villa no.:</label>
                      <input name="address2" type="text" className="form-control" placeholder="Floor, Apartment, Villa no."
                        onChange={onAddressInputFieldChanged} value={address2} />
                      <p className="m-0 text-danger">{address2_err}</p>
                    </div>
                    <div className="form-group">
                      <label className="mb-0">Emirate:</label>
                      <select
                        name="emirate"
                        className="w-100 form-control"
                        onChange={onAddressInputFieldChanged}
                        value={emirate}
                      >
                        <option value="">--- Select emirate ---</option>
                        {
                          props.countryId == 1 ?
                            <>
                              <option value="Dubai">Dubai</option>
                              <option value="Abu Dhabi">Abu Dhabi</option>
                              <option value="Sharjah">Sharjah</option>
                              <option value="Ajman">Ajman</option>
                              <option value="Umm Al Quwain">Umm Al Quwain</option>
                              <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                              <option value="Fujairah">Fujairah</option>
                            </>
                            :
                            <></>
                        }
                      </select>
                      <p className="m-0 text-danger">{emirate_err}</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={editAddressBtnClicked}
                      className="save-address">Save Address</Button>
                  </Modal.Footer>
                </Modal>

                {/* Shipping Method Start */}
                {
                  props.cart_items?.line_items?.filter(line_item => line_item.orderable?.pickup_allowed == true)?.length == props.cart_items?.line_items?.length ?
                    <>
                      <div className="payment-method-text mt-2 mb-2 text-uppercase font-weight-bold">
                        {
                          props.language == "en" ?
                            en_translation["my-account-logged-in"]["select-a-shipping-method"]
                            :
                            ar_translation["my-account-logged-in"]["select-a-shipping-method"]
                        }
                      </div>

                      <Row
                        noGutters={true}
                        className="shipping-options shipping-row margin-bottom-4 br-2"
                      >
                        <Col
                          md={12}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <button
                            onClick={onClickCollectClicked}
                            className={`collect-button card-button ${is_delivery ? "gray-button" : ""}`}
                          >
                            <p className="title">
                              {
                                props.language == "en" ?
                                  en_translation["my-account-logged-in"]["click-and-collect"]
                                  :
                                  ar_translation["my-account-logged-in"]["click-and-collect"]
                              }
                            </p>
                            <p className="m-0 text">
                              {
                                props.language == "en" ?
                                  en_translation["my-account-logged-in"]["click-and-collect-text"]
                                  :
                                  ar_translation["my-account-logged-in"]["click-and-collect-text"]
                              }
                            </p>
                          </button>
                          <button
                            onClick={onHomeDeliveryClicked}
                            className={`collect-button cash-button ${is_delivery ? "" : "gray-button"}`}
                          >
                            <p className="title">
                              {
                                props.language == "en" ?
                                  en_translation["my-account-logged-in"]["home-delivery"]
                                  :
                                  ar_translation["my-account-logged-in"]["home-delivery"]
                              }
                            </p>
                            <p className="m-0 text">
                              {
                                props.language == "en" ?
                                  en_translation["my-account-logged-in"]["home-delivery-text"]
                                  :
                                  ar_translation["my-account-logged-in"]["home-delivery-text"]
                              }
                            </p>
                          </button>
                        </Col>
                      </Row>
                    </>
                    :
                    <></>
                }

                {/* Shipping Method End */}

                {
                  props.cart_items?.line_items?.filter(line_item => line_item.orderable?.pickup_allowed == true)?.length == props.cart_items?.line_items?.length ?
                    is_delivery ?
                      <>
                        {/* Addresses Start */}

                        <div className="payment-method-text mt-2 mb-2 text-uppercase font-weight-bold">
                          {t("my-account-logged-in.your-shipping-address")}
                        </div>
                        {
                          props.address?.length > 0 ?
                            <div className="card addresses-card mb-3">
                              <div className="card-body py-0">
                                {props.address.map(
                                  (address) =>
                                    (address.country.toLowerCase() ===
                                      props.country.toLowerCase() ||
                                      address.country.toLowerCase() === country_code) && address.default && (
                                      <div
                                        className="d-flex bg-white address-card py-0 justify-content-between align-items-center"
                                        key={address.id}
                                      // style={{
                                      //   border: address.default ?
                                      //     "1px solid #3866df"
                                      //     :
                                      //     "1px solid rgba(0,0,0,.125)"
                                      // }}
                                      >
                                        <div
                                          onClick={() => {
                                            if (localStorage.getItem("guest_user_address")) {
                                              onAddAddressClicked();
                                            }
                                            else {
                                              onEditAddressClicked(address);
                                            }
                                          }}
                                          className="d-flex align-items-center w-100">
                                          {/* <div
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    borderRadius: "50%",
                                    border: "2px solid #003366",
                                    background: address.default ? "#003366" : "white"
                                  }}
                                ></div> */}
                                          <div>
                                            <p className="my-0 font-weight-bold user_name">{address.first_name}</p>
                                            <p className="my-0 info">{address.address1} - {address.address2}</p>
                                            <p className="my-0 info">Phone Number:&nbsp;{address.contact_number}</p>
                                          </div>
                                        </div>
                                        {
                                          localStorage.getItem("guest_user_address") ?
                                            <div
                                              onClick={onAddAddressClicked}
                                              className="view-edit-btn text-center mt-1">
                                              <p className="m-0">Change</p>
                                            </div>
                                            :
                                            <div>
                                              <div
                                                onClick={() => onEditAddressClicked(address)}
                                                className="view-edit-btn">
                                                <p className="m-0">View / Edit</p>
                                              </div>
                                              {
                                                props.address?.length > 1 ?
                                                  <div
                                                    onClick={onChangeAddressesClicked}
                                                    className="view-edit-btn text-center mt-1">
                                                    <p className="m-0">Change</p>
                                                  </div>
                                                  :
                                                  <></>
                                              }
                                            </div>
                                        }
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                            :
                            <></>
                        }
                        <div
                          onClick={onAddAddressClicked}
                          className="add-address-btn">
                          <p className="my-0">{t("select-shipping-address.add-new-address")}</p>
                        </div>

                        {/* Addresses End */}
                      </>
                      :
                      <div className="pickup-addresses">
                        <div className="payment-method-text mt-2 mb-2 text-uppercase font-weight-bold">
                          {
                            props.language == "en" ?
                              en_translation["my-account-logged-in"]["select-pickup-address"]
                              :
                              ar_translation["my-account-logged-in"]["select-pickup-address"]
                          }
                        </div>
                        {
                          pickup_addresses?.map(address => {
                            return (
                              <div
                                className={
                                  "pickup-address" + (
                                    props.order.createdAddress?.pickup_address?.id == address.id ?
                                      " active"
                                      :
                                      ""
                                  )
                                }
                                onClick={() => onPickupAddressSelected(address.id)}
                              >
                                {
                                  props.order.createdAddress?.pickup_address?.id == address.id ?
                                    <BsCheckCircle size={20} color="#003366" />
                                    :
                                    <BsCircle color="gray" />
                                }
                                <p>{address.store_address}</p>
                              </div>
                            )
                          })
                        }
                        <div className="customer-info">
                          <div className="payment-method-text mt-2 mb-2 text-uppercase font-weight-bold">
                            {
                              props.language == "en" ?
                                en_translation["my-account-logged-in"]["customer-info"]
                                :
                                ar_translation["my-account-logged-in"]["customer-info"]
                            }
                          </div>
                          <div className="row">
                            <div className="col-sm-6 form-group">
                              <label className="mb-0">{
                                props.language == "en" ?
                                  en_translation["my-account-logged-in"]["customer-name"]
                                  :
                                  ar_translation["my-account-logged-in"]["customer-name"]
                              }</label>
                              <input
                                type="text"
                                value={customer_name}
                                placeholder="Customer Name"
                                onChange={(e) => setCustomerName(e.target.value)}
                                className="form-control"
                              />
                            </div>
                            <div className="col-sm-6 form-group">
                              <label className="mb-0">{
                                props.language == "en" ?
                                  en_translation["my-account-logged-in"]["customer-contact"]
                                  :
                                  ar_translation["my-account-logged-in"]["customer-contact"]
                              }</label>
                              <PhoneInput
                                inputProps={{ name: "customer_contact", maxLength: 17 }}
                                placeholder={"Customer Contact"}
                                countryCodeEditable={false}
                                onlyCountries={["ae"]}
                                country={"ae"}
                                value={customer_contact}
                                onChange={(val) => setCustomerContact(val)}
                                enableLongNumbers={false}
                                inputStyle={{
                                  width: "100%"
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    :
                    <>
                      {/* Addresses Start */}

                      <div className="payment-method-text mt-2 mb-2 text-uppercase font-weight-bold">
                        {t("my-account-logged-in.your-shipping-address")}
                      </div>
                      {
                        props.address?.length > 0 ?
                          <div className="card addresses-card mb-3">
                            <div className="card-body py-0">
                              {props.address.map(
                                (address) =>
                                  (address.country.toLowerCase() ===
                                    props.country.toLowerCase() ||
                                    address.country.toLowerCase() === country_code) && address.default && (
                                    <div
                                      className="d-flex bg-white address-card py-0 justify-content-between align-items-center"
                                      key={address.id}
                                    // style={{
                                    //   border: address.default ?
                                    //     "1px solid #3866df"
                                    //     :
                                    //     "1px solid rgba(0,0,0,.125)"
                                    // }}
                                    >
                                      <div
                                        onClick={() => {
                                          if (localStorage.getItem("guest_user_address")) {
                                            onAddAddressClicked();
                                          }
                                          else {
                                            onEditAddressClicked(address);
                                          }
                                        }}
                                        className="d-flex align-items-center w-100">
                                        {/* <div
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    borderRadius: "50%",
                                    border: "2px solid #003366",
                                    background: address.default ? "#003366" : "white"
                                  }}
                                ></div> */}
                                        <div>
                                          <p className="my-0 font-weight-bold user_name">{address.first_name}</p>
                                          <p className="my-0 info">{address.address1} - {address.address2}</p>
                                          <p className="my-0 info">Phone Number:&nbsp;{address.contact_number}</p>
                                        </div>
                                      </div>
                                      {
                                        localStorage.getItem("guest_user_address") ?
                                          <div
                                            onClick={onAddAddressClicked}
                                            className="view-edit-btn text-center mt-1">
                                            <p className="m-0">Change</p>
                                          </div>
                                          :
                                          <div>
                                            <div
                                              onClick={() => onEditAddressClicked(address)}
                                              className="view-edit-btn">
                                              <p className="m-0">View / Edit</p>
                                            </div>
                                            {
                                              props.address?.length > 1 ?
                                                <div
                                                  onClick={onChangeAddressesClicked}
                                                  className="view-edit-btn text-center mt-1">
                                                  <p className="m-0">Change</p>
                                                </div>
                                                :
                                                <></>
                                            }
                                          </div>
                                      }
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                          :
                          <></>
                      }
                      <div
                        onClick={onAddAddressClicked}
                        className="add-address-btn">
                        <p className="my-0">{t("select-shipping-address.add-new-address")}</p>
                      </div>

                      {/* Addresses End */}
                    </>
                }

                <PaymentMethod
                  id={props.id}
                  triggerWallet={(val) => setWallet(val)}
                  currency={
                    props.country.toLowerCase() === "saudi arabia"
                      ? "SAR"
                      : props.country.toLowerCase() === "united arab emirates"
                        ? "AED"
                        : "EGP"
                  }
                  is_delivery={is_delivery}
                  customer_name={customer_name}
                  customer_contact={customer_contact}
                />
              </Col>
              <Col md={12} lg={4} className="summary-col mr-auto">
                <div className="mb-3 order-items">
                  {props.cart_items?.line_items?.map((item) => (
                    <OrderItems
                      key={item.id}
                      currency={props.currency}
                      order={item}
                      total={props.cart_items.sub_total}
                      getDraftData={(val) => getDraftData()}
                    />
                  ))}
                </div>
                <OrderSummary wallet={wallet} address={props.address} defaultAddress={props.address.filter(address => address.default == true)} />
              </Col>
            </Row>

            <div className="checkout-page-footer">
              <ShippingFooter />
            </div>
          </>
        )}
      </Container>
      {redirect && (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/"}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    address: state.select.address,
    cart_items: state.cart.cart_items,
    country: state.language.country,
    direction: state.layout.direction,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    order: state.cart.order,
    auth: state.auth.isSignedIn,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  set_cart_items,
  set_loading,
  set_addresses
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
