import React, { Fragment } from "react";
import { IoMdClose } from "react-icons/io";
import Logo from "../../assets/images/ALISOUQ-LOGO.png";
import { connect } from "react-redux";
import "../../assets/styles/mobile/myaccountloggedoutmob.scss";
import { FaUser } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import ListItems from "../../components/mobile/MyAccount/ListItems";
import { ReactComponent as accountLanguage } from "../../assets/icons/mobile-account/accountLanguage.svg";
import { ReactComponent as accountRegion } from "../../assets/icons/mobile-account/accountRegion.svg";
import LinksAndTerms from "../../components/mobile/MyAccount/SocialLinksAndTerms";
import MobileLayoutRenderer from "./layout/MobileLayoutRenderer";
import { set_direction } from "../../redux/actions/LayoutAction";
import { useTranslation } from "react-i18next";

import uae from "../../assets/images/flag.png";
import saudia from "../../assets/images/flag2.png";
import egypt from "../../assets/images/flag3.png";
import whatsappicon from "../../assets/images/My Account/whatsapp.png";
import { Link, useHistory } from "react-router-dom";

const MyAccountLoggedOutMob = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const page_settings = t("my-account-logged-out.settings-label");
  const Settings_List = [
    {
      id: 1,
      icon: accountRegion,
      title: t("my-account-logged-in.country"),
      rightIcon:
        props.country.toLowerCase() === "United Arab Emirates".toLowerCase()
          ? uae
          : props.country.toLowerCase() === "Saudi Arabia".toLowerCase()
            ? saudia
            : egypt,
    },
    {
      id: 2,
      icon: accountLanguage,
      title: t("my-account-logged-in.language"),
      rightIcon: props.language === "en" ? "English" : "العربية",
    },
  ];

  const reach_out_to_us_heading = t("my-account-logged-in.reach-out-title");
  const Reach_Out_To_Us_List = [
    {
      id: 2,
      icon: accountLanguage,
      title: t("my-account-logged-in.contact-us"),
      // title: "Contact Us",
      rightIcon: "",
    },
  ];

  const cls = props.language.toLowerCase() === "en" ? "english" : "arabic";

  return (
    <>
      {props.width === "sm" && (
        <MobileLayoutRenderer
          NavbarTop={false}
          BackBtn={false}
          CancelBtn={false}
          CloseIcon={false}
          NavbarBottom={true}
        >
          <Fragment>
            <div className="my-account-page-logged-out-mobile">
              <div className={`cross-icon ${cls}`}>
                <IoMdClose onClick={() => history.goBack()} size="35" />
              </div>

              <div className="logo-main">
                <div className="logo-cont">
                  <div className="logo-size">
                    <Link to={"/" + props.countryUrl + "-" + props.language}>
                      <img src={Logo} width="100%" height="100%" alt="logo" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="auth-account">
                <Link to="/login">
                  <div className="sign-in">
                    <div className="div-circle">
                      <FaUser size={25} />
                    </div>
                    <label className="auth-label">
                      {t("my-account-logged-out.sign-in")}
                    </label>
                  </div>
                </Link>
                <Link to="/signup">
                  <div className="sign-up">
                    <div className="div-circle">
                      <FaUserPlus size={30} />
                    </div>
                    <label className="auth-label">
                      {t("my-account-logged-out.sign-up")}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="settings-list-items">
                <ListItems
                  authorized={false}
                  List={Settings_List}
                  header={page_settings}
                />
              </div>
              <div className="settings-list-items">
                <ListItems
                  authorized={false}
                  List={Reach_Out_To_Us_List}
                  header={reach_out_to_us_heading}
                />
              </div>
              <hr className="my-0 mx-3" />
              <div className="my-account-list-items-mobile">
                <div className="list-main">
                  <a href="//api.whatsapp.com/send?phone=971543064845&amp;text=Hi, I need assistance"
                    className="list-items"
                    style={{
                      fontSize: "13px",
                      textDecoration: "none",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginRight: "15px",
                      backgroundColor: "white",
                      height: "60px"
                    }}
                  >
                    {
                      props.language == "en" ?
                        <img style={{
                          marginRight: "30px"
                        }}
                          src={whatsappicon}
                          alt="flag"
                          width="30px"
                          height="30px"
                        />
                        :
                        <img style={{
                          marginLeft: "30px"
                        }}
                          src={whatsappicon}
                          alt="flag"
                          width="30px"
                          height="30px"
                        />
                    }
                    {t("contact-us.whatsapp")}
                  </a>
                </div>
              </div>
              <div className="socialIcons">
                <LinksAndTerms loggedIn={false} />
              </div>
            </div>
          </Fragment>
        </MobileLayoutRenderer>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.language.country,
    width: state.layout.screenWidth,
    direction: state.language.direction,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_direction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountLoggedOutMob);
