import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/pages/customer/AllItems/ItemsPage.scss";
import { set_all_items } from "../../../redux/actions/AllItemsAction";
import { connect } from "react-redux";
import { apiJson } from "../../../Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import MobileLayoutRenderer from "../../mobile/layout/MobileLayoutRenderer";
import { set_loading } from "../../../redux/actions/LayoutAction";
import ProductCard from "../../../components/mobile/Home/ProductCardMobile";
import "../../../assets/styles/footer-pages/offersmob.scss";
import HeaderMobile from "../../../components/mobile/HeaderMobile";
import { FiTruck } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import NotifyUser from "../../../components/product/NotifyUser";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

function NewOffers(props) {
  const [offers_sections, setOffersSections] = useState([]);
  const [top_banner, setTopBanner] = useState();
  const [boxes_banners, setBoxesBanners] = useState([]);
  const [showNotifyModel, setShowNotifyModel] = useState(false);
  const [orderableId, setOrderableId] = useState(null);
  const { t } = useTranslation();

  let message = "";

  useEffect(() => {
    document.title = "Alisouq.com - Offers";
  }, []);

  useEffect(() => {
    apiJson.newoffers.getTopBanner(1).then(async (response) => {
      if (response.success) {
        setTopBanner(response.data);
      }
    });

    apiJson.newoffers.getBoxesBanners(1).then(async (response) => {
      if (response.success) {
        setBoxesBanners(response.data);
      }
    });
    apiJson.newoffers.offers_sections(props.countryId)
      .then(response => {
        if (response.success) {
          setOffersSections(response.data.sections);
        }
      });
  }, []);

  return (
    <div className="items-page-mobile items-page-desktop">

      <MobileLayoutRenderer
        NavbarTop={false}
        BackBtn={false}
        CancelBtn={false}
        NavbarBottom={true}
        SearchIcon={true}
      >
        <div className="home-mob">
          <HeaderMobile />
        </div>
        <img src={top_banner?.mobile_image} style={{ width: "100%", height: "140px" }} />
        <div className="freeshipmob">

          <p className="m-0 text-center freshiptxt"><FiTruck size={18} />&nbsp;&nbsp;Free shipping on orders above AED 99</p>

        </div>
        <div className="row offerrow xyz"
          style={{
            marginLeft: "0px !important",
            marginRight: "0px !important",
            width: "100%",
          }}
        >
          {
            boxes_banners.length > 0 ?
              boxes_banners.map(banner => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <a href={banner.link}>
                      <img className="img-fluid"
                        src={banner.mobile_image}
                        alt="category"
                        style={{
                          width: "100%",
                        }}
                      ></img>
                      <h6 className="offctxt">{banner.title}</h6>
                    </a>
                  </div>
                )
              })
              :
              <></>
          }
          {/* <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/168">
              <img className="img-fluid"
                src={ramadanimg2}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Summer Specials</h6>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/167">
              <img className="img-fluid"
                src={ramadanimg3}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Buy 1 Get 1</h6>
            </a>
          </div> */}
        </div>

        {/* <div className="row offerrow xyz"
          style={{
            marginLeft: "0px !important",
            marginRight: "0px !important",
            width: "100%",
          }}
        >
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/166">
              <img className="img-fluid"
                src={ramadanimg1}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Cleaners</h6>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/168">
              <img className="img-fluid"
                src={ramadanimg2}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Best Sellers</h6>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            <a href="offers/section/167">
              <img className="img-fluid"
                src={ramadanimg3}
                alt="category"
                style={{
                  width: "100%",
                }}
              ></img>
              <h6 className="offctxt">Up To 70% OFF</h6>
            </a>
          </div>
        </div> */}

        <div className="all-page-container m-auto">
          <div className="container">
            <h2 className="sectiontitle-individualpage">Shop by Brands</h2>
            <div className="emptyspacer"></div>
            <Swiper
              slidesPerView={4} // or 'auto'
              slidesPerColumn={2}
              slidesPerColumnFill="row"
              spaceBetween={10}
              navigation
              className="shop-by-brands"
            >
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=269"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/cBNsVEmg2k6WLXU8DPiF5d.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=164"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/peSAhEhokenQ9EjtR2YGq7.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=282"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/2t7LkpbFt9ZxP2wTBhrNSc.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=277"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/prL5bW2eJnENeohbdUMaNJ.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=273"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/e5M9yfkWTfuaMPJ2ogi3jy.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=270"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/pu7KzRCsUacSKFuNkwCUKr.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=271"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/AjfbvCk3VuBk8cGXvDtNs.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=384"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jj8j7YZJiCyZJJXWgjSx3B.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=292"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jZav9RgF21QuY73Pqqiw2o.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=328"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iWsLuHBrv6fbjnqahF1NTd.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=487"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/unATv96BjLDmE77JKTNQSf.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=168"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3PwLdMpgncAARimuFawCwK.jpeg"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=195"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ttpWPXDGWaBkJt7fgffp5q.jpeg"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=388"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/uhAC8eaF6vMC1jYjMApUBW.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=283"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/kz1MPy5YVpvYM5t86QUXR7.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=178"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/tP1RDF6oiNm6i9hxUHXKYv.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=281"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/xkavF6T544k2JgQk6wLMMN.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=67"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9sYGBVBAbzhyBFCoKfQhaQ.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=415"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fDcVJRrX5r45Y3zAUNG8W2.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=171"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ep7BhDu7z286wNP262KLtt.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=73"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YvGp9qJBuUhj6nhbQX3ue.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=413"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/cjrqdRMj5VQPTBvSRBtAwU.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=453"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/waTUrjpjRM9TYip1d4oHws.jpeg"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=387"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/4c49bVAbDLoqgfHsVcxjbX.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=452"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/863yzQkyrVYPXi2YWN8RQG.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=488"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
              <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key={1}
              >
                <Link to={{
                  pathname: "/" + props.countryUrl +
                    "-" + props.language + "/items/products",
                  search: "filter_by_brand%5B%5D=510"
                }}>
                  <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/6itpqKYwmo2wSzipBPvXt9.png"
                    className="img-img-fluid paintbrand"
                    height="80px"
                  />
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="mobile-sections">
            {
              offers_sections.map(section => {
                return (
                  <Row
                    key={0}
                    className={`product-list-row mx-auto`}
                  >
                    <div className="title-row">
                      <div className="title mobtit"
                        dangerouslySetInnerHTML={{
                          __html: section.title_en,
                        }}
                      ></div>
                      <div class="viewalldiv">
                        <Link
                          to={
                            "/" + props.countryUrl + "-" + props.language + "/new-offers/section/" + section.url
                          }
                          className="view-all-text">
                          {t("all-categories.view-all")}
                        </Link>
                      </div>
                    </div>
                    <div className="product-cont mt-3">
                      {section.offers_section_variants.map((data) => (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname:
                              "/" +
                              props.countryUrl +
                              "-" +
                              props.language +
                              `/${data.variant.category_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${data.variant.product_slug}`,
                            state: {
                              id: data.variant.id,
                              type: data.variant ? "products" : "services",
                              sku: data.variant.sku
                            },
                            search: "?sku=" + data.variant.sku
                          }}
                          key={data.variant.id + Math.random()}
                        >
                          <div
                            className="image-cont"
                            key={data.variant.id + Math.random() + "prod"}
                          >
                            <ProductCard
                              key={data.variant.id + Math.random()}
                              setShowNotifyModel={setShowNotifyModel}
                              setOrderableId={setOrderableId}
                              img={
                                data.variant
                                  ? data.variant.featured_image
                                  : data.featured_image
                              }
                              storeLogo={data.variant.store_logo}
                              wishlisted={
                                data.variant
                                  ? data.variant.wishlisted
                                  : data.wishlisted
                              }
                              home={true}
                              type={data.variant ? "products" : "services"}
                              id={data.variant ? data.variant.id : data.id}
                              rating={data.variant.average_rating}
                              reviews={data.variant.reviews_count}
                              name={data.variant[`product_name_${props.language}`] + " " + data.variant[`name_${props.language}`]}
                              data={data.variant ? data.variant : data}
                              coupon={data.variant?.coupon}
                              is_inquiry={data.is_inquiry}
                            />
                          </div>
                        </Link>
                      ))}
                    </div>
                    <div />
                  </Row>
                )
              })
            }
          </div>
        </div>
      </MobileLayoutRenderer>

      <NotifyUser
        setShowNotifyModel={setShowNotifyModel}
        showNotifyModel={showNotifyModel}
        id={orderableId}
        language={props.language}
        isSmall={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    seller: state.allItems.seller,
    filter_by: state.allItems.filter_by,
    brand: state.allItems.brand,
    items: state.allItems.items,
    countryId: state.language.countryId,
    countryUrl: state.language.countryUrl,
    width: state.layout.screenWidth,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_all_items,
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOffers);