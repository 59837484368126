import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/images/ALISOUQ-LOGO.png";
import hamburger from "../../assets/images/hamburger1.png";
import { RiSearchLine } from "react-icons/ri";
import { ReactComponent as MobileCartInactive } from "../../assets/icons/MobileCartInactive.svg";
import { set_redirect_home } from "../../redux/actions/NotFoundAction";
import dsspic from "../../assets/images/individual-pages/dss/saleb6.png";
import { FiBell } from "react-icons/fi";

function HeaderMobile(props) {
  const [redirect, setRedirect] = useState({ value: false });

  return (
    <div>
      <div className="header">
        <div className="logo">
          <span
            onClick={() => {
              setRedirect({
                value: true,
                path: localStorage.getItem("access_token")
                  ? "/" +
                  props.countryUrl +
                  "-" +
                  props.language +
                  "/my-account-li-mob"
                  : "/" +
                  props.countryUrl +
                  "-" +
                  props.language +
                  "/my-account-lo-mob",
              });
              const timer = setTimeout(() => {
                setRedirect({ value: false });
              }, 1000);
              return () => clearTimeout(timer);
            }}
          >
            <img src={hamburger} style={{ height: "20px", marginRight: "10px", marginBottom: "10px" }} />
          </span>
        </div>
        <Link
          to={{
            pathname:
              "/" +
              props.countryUrl +
              "-" +
              props.language
          }}
          className="mx-auto"
        >
          <img src={logo} width="auto" height="40" alt="" />
        </Link>
        <Link
          to={{
            pathname:
              "/" +
              props.countryUrl +
              "-" +
              props.language +
              "/all-categories"
          }}
        >
          <div className="search">
            <RiSearchLine
            // onClick={() =>
            //   setRedirect({ value: true, path: "all-categories" })
            // }
            />
          </div>
        </Link>
        {/* {localStorage.getItem("access_token") && (
          <Link className="cart-col mr-2 pl-md-0" style={{ marginLeft: props.lang === "ar" && "10px" }}
            to={{
              pathname:
                "/" +
                props.countryUrl +
                "-" +
                props.language +
                "/notifications"
            }}
          >
            <FiBell
              fontSize={22}
              style={{
                color: "rgb(35 45 71)",
                cursor: "pointer",
                strokeWidth: 1
              }}
            />
            <span
              style={{
                left: props.lang === "ar" ? "53px" : "",
                right: props.lang === "en" ? "33px" : "",
              }}
              className="cart-counter"
            >
              {props.notifications_count || 0}
            </span>
          </Link>
        )} */}
        <div
          onClick={() => {
            setRedirect({
              value: true,
              path: "/" + props.countryUrl + "-" + props.lang + "/cart",
            });
            const timer = setTimeout(() => {
              setRedirect({ value: false });
            }, 1000);
            return () => clearTimeout(timer);
          }}
        >
          <div className="cart-col">
            <span
              style={{
                left: props.lang === "ar" ? "22px" : "",
                right: props.lang === "en" ? "0px" : "",
              }}
              className="cart-counter"
            >
              {props.cart_items_length ? props.cart_items_length : 0}
            </span>
            <span>
              <MobileCartInactive width="20" height="20" />
            </span>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
      {/* <Link to="/uae-en/dss" style={{ marginTop: "55px", display: "block" }}>
        <img style={{ width: "100%", height: "auto" }} width="585" height="80" className="dssstrip" src={dsspic}></img>
      </Link> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.home.categories,
    products: state.vendor.products,
    services: state.vendor.services,
    sections: state.home.sections,
    positionOne: state.home.positionOne,
    positionTwo: state.home.positionTwo,
    positionThree: state.home.positionThree,
    positionFour: state.home.positionFour,
    positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
    lang: state.language.language,
    cart_items: state.cart.cart_items,
    countryUrl: state.language.countryUrl,
    cart_items_length: state.cart.cart_items_length,
    notifications_count: state.notifications.count
  };
};

const mapDispatchToProps = {
  set_redirect_home
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);