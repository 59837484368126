import React, { Fragment } from "react";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/alisuq_story.jpg";
import alisuqstorybanner from "../../assets/images/footer-page-images/alisuqstory.jpg";
import aliimg from "../../assets/images/footer-page-images/alialrais2.png";
import raniaimg from "../../assets/images/footer-page-images/raniasaied2.png";

import "../../assets/styles/footer-pages/alisuqstory.scss";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { useEffect } from "react";
import $ from "jquery";

const AlisuqStory = () => {
  useEffect(() => {
    window.document.title = "Alisouq Story";

    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>
        <div className="content-wrapper all-page-container">
          <div className="footer-pages-content" style={{textAlign:"-webkit-center"}}>
            <div className="alisuqstorycontent">
            <p>
              Alisouq is an online marketplace for materials, do-it-yourself
              products and tools. As an one-stop destination for consumers and
              corporate clients, Alisouq offers an extensive and fast-growing
              array of products and tools of highest quality from leading brands
              around the world.
            </p>
            <p>
              Alisouq is owned and operated by Alrais Enterprises Group, a
              diverse conglomerate based in Dubai, United Arab Emirates with
              business presence that extends through the Middle East, Europe and
              Southeast Asia. As a hallmark of its holding entity, Alisouq is
              synonymous to excellence and efficiency in delivering
              customer-centric and secure online shopping experience.
            </p>
            <p>
              Our intuitive interface, that brings all relevant product
              information in one look, enables our clients to shop with ease and
              convenience while having the peace of mind that all transactions
              and financial information are fully secure and protected. Alisouq
              offers the most competitive pricing across all our products and
              categories.
            </p>
            <p>
              With it's parent company at the helm, Alisouq draws its strength on
              the stability and integrity of its leadership and experience for
              25 years and counting. Our efforts and dedication offers you the
              confidence that you can always find the right product and tool to
              do things yourself, at a winning price, winning customer service
              and winning value.
            </p>
            <p>
              We'd love to hear from you through:{" "}
              <strong>customerservice@alisouq.com</strong>.
            </p>
            </div>
            <div className="pagesbanner">
              <img
                src={alisuqstorybanner}
                alt="alisuq-story"
                style={{
                  width: "80%",
                  height: "100%",
                }}
              ></img>
            </div>
            <div className="emptyspace"></div>
            <h4 className="subheadings">Vision</h4>

            <p>
              Empowering, enabling, and providing choice to people everywhere,
              everyday.
            </p>
            <div className="emptyspace"></div>
            <h4 className="subheadings">Mission</h4>

            <p>
              To create a world where our consumers have access to a finely
              curated, authentic assortment of products and services that
              delight and elevate the human spirit
            </p>
            <div className="emptyspace"></div>
            <h4 className="subheadings">Values</h4>
            <div className="emptyspace"></div>
            <h4 className="subheadings">Our Leadership Team</h4>

            <div className="emptyspace"></div>
            <div class="container">
              <div class="row">
                <div class="col-sm-6 first-column">
                  <div className="team">
                    <img
                      src={aliimg}
                      style={{
                        width: "40%",
                        boxShadow: "3px 2px 6px 1px #7777776e",
                        borderRadius: "12px",
                      }}
                      alt="mrali-alisuq-story"
                    ></img>
                    <div className="emptyspace"></div>
                    <h3 className="leadername">Ali M Alrais</h3>
                    <p>Founder &amp; Group CEO</p>
                    <blockquote>
                      The world and its economy is rapidly changing. Alisouq was
                      built with the entrepreneurial spirit and energy to cope
                      with these changes and accommodate changes in the
                      construction &amp; building industry.
                    </blockquote>
                  </div>
                </div>
                <div class="col-sm-6 second-column">
                  <div className="team">
                    <img
                      src={raniaimg}
                      style={{
                        width: "40%",
                        boxShadow: "3px 2px 6px 1px #7777776e",
                        borderRadius: "12px",
                      }}
                      alt="rania-alisuq-story"
                    ></img>

                    <div className="emptyspace"></div>
                    <h3 className="leadername">Rania Saied</h3>
                    <p>Group COO</p>

                    <blockquote style={{ width: "350px" }}>
                      We want to introduce the importance of choosing what goes
                      in your home, how it looks, and how its maintained. Alisouq
                      is the 1st platform to provide all building and DIY
                      solutions to people in UAE, empowering them to own their
                      home the way they want.
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <WebsiteFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default AlisuqStory;
