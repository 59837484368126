import React, { Fragment, useEffect } from "react";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-pages-banners/Payment-OPtions.jpg";
import "../../assets/styles/footer-pages/PaymentOptions.scss";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import $ from "jquery";

const PaymentOptions = () => {
  useEffect(() => {
    window.document.title = "Payment Options";
    $("html, body").animate({ scrollTop: 0 }, "fast");
  }, []);
  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <WebsiteHeader />
        <div className="pagesbanner">
          <img
            src={pagesbannerimg}
            alt="category"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
            }}
          ></img>
        </div>
        <div className="content-wrapper all-page-container">
          <div className="footer-pages-content"  style={{
              marginTop: "1.5em",
              textAlign: "left"
            }}>
     
<b>Are online payments secure?</b>

<p>Your security is very important to us. We work closely with our delivery team to ensure Cash On Delivery orders are safe. You
will receive an invoice by email upon delivery to confirm your payment. If paying by debit or credit card, Alisouq provides safe
and secure checkout by encrypting your credit card information using SSL.</p>
<b>Payment Methods:</b>
<ul>
<li>Cash on Delivery</li>
<li>Credit Cards</li>
<li>Debit Cards</li>
<li>Alisouq Wallet</li>
<li>Gift Cards, eGift Cards</li>
</ul>
    <b>1. Cash on Delivery</b>
<p>Alisouq provides a COD option. Buy online and pay when the order is delivered to you. The maximum order value for
COD is AED 1,000. Only cash payments will be accepted. Any other
payment methods like personal cheque or credit card will not be accepted.</p>
<b>2. Credit & Debit Cards</b>
<p>We currently accept Visa and Master Card credit and debit cards. You can store multiple credit cards to use for your online
purchase on Alisouq.com. During this step in the checkout process, you can add, edit, or delete your information. Select
one credit or debit card for the payment of your order.
When your credit or debit card is authorized, funds are put on hold until the transaction is completed. When the order is
shipped, the payment is processed.
Your billing information must be exactly as it appears on your financial statement. If any numbers are different, the order
cannot be processed. Be sure to correctly spell city names and avoid using abbreviations unless that is how your
information appears on your statement.
Contact your financial institution if you need the exact billing address associated with your credit or debit card.
For your security, Alisouq may require you to enter your 3- or 4-digit card identification number (CVV) on certain orders for
Visa, MasterCards</p>

<b>3. Alisouq Wallet</b>
<p>Online credits are automatically applied to your Alisouq.com order as the first method of payment. The remaining balance
can be paid by credit card, Gift Cards or eGift Cards (only available on www.alisouq.com). There are no codes to
remember or numbers to type. Alisouq wallet is not redeemable or exchangeable for cash.
To view your online credit, visit My Account on your Alisouq.com account.</p>
<b>4. Gift Cards, eGift Cards</b>
<p>Gift Cards are physical cards with a 16-digit card number and an 8-digit PIN. eGift Cards are virtual cards with a 16-digit
card number and an 8-digit PIN.
Gift Cards and eGift Cards can be used online, on our mobile app. Any unused balance will remain on the card and can
be used toward a future purchase.
If using a gift card to pay for your order, please retain the gift card until the order has been received. If the order total
changes due to cancellations, the balance will be reinstated on the gift card.
To check your gift card or egift card balance visit .................. on your Alisouq.com account
            </p>
        
          </div>
        </div>
        <div className="w-100">
          <WebsiteFooter />
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentOptions;
