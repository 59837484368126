import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import "../../assets/styles/category-homepages/ramadan-page.scss";

import ProductList from "../../components/product/ProductList";

import TopBanner1 from "../../assets/images/eid-sale/2025/eid-sale-banner.png";
import { FaInfoCircle } from "react-icons/fa";
import cat1 from "../../assets/images/eid-sale/2025/top-categories/1.png";
import cat2 from "../../assets/images/eid-sale/2025/top-categories/2.png";
import cat3 from "../../assets/images/eid-sale/2025/top-categories/3.png";
import cat4 from "../../assets/images/eid-sale/2025/top-categories/4.png";
import cat5 from "../../assets/images/eid-sale/2025/top-categories/5.png";
import cat6 from "../../assets/images/eid-sale/2025/top-categories/6.png";
import cat7 from "../../assets/images/eid-sale/2025/top-categories/7.png";
import cat8 from "../../assets/images/eid-sale/2025/top-categories/8.png";
import cat9 from "../../assets/images/eid-sale/2025/top-categories/9.png";
import cat10 from "../../assets/images/eid-sale/2025/top-categories/10.png";

import EidEssential1 from "../../assets/images/eid-sale/2025/top-essentials/1.png";
import EidEssential2 from "../../assets/images/eid-sale/2025/top-essentials/2.png";
import EidEssential3 from "../../assets/images/eid-sale/2025/top-essentials/3.png";
import EidEssential4 from "../../assets/images/eid-sale/2025/top-essentials/4.png";
import EidEssential5 from "../../assets/images/eid-sale/2025/top-essentials/5.png";
import EidEssential6 from "../../assets/images/eid-sale/2025/top-essentials/6.png";

import CategoryBanner1 from "../../assets/images/ramadan-offer-2025/banners_grid/1217x320/1.png";
import CategoryBanner2 from "../../assets/images/ramadan-offer-2025/banners_grid/1217x320/2.png";

import CleaningBanner1 from "../../assets/images/eid-sale/2025/banners/1.png";
import CleaningBanner2 from "../../assets/images/eid-sale/2025/banners/2.png";
import CleaningBanner3 from "../../assets/images/eid-sale/2025/banners/3.png";
import CleaningBanner4 from "../../assets/images/eid-sale/2025/banners/4.png";

import storagebanner from "../../assets/images/eid-sale/2025/banners/5.png";

import { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const EidSale = (props) => {
    const [products1, setProducts1] = useState([]);
    const [products2, setProducts2] = useState([]);
    const [products3, setProducts3] = useState([]);
    const [products4, setProducts4] = useState([]);
    const [products5, setProducts5] = useState([]);

    useEffect(() => {
        window.document.title = "Eid Sale - Alisuq";

        $("html, body").animate({ scrollTop: 0 }, "fast");

        apiJson.
            sections.getSectionProducts(
                116,
                props.countryId,
                1,
                10
            )
            .then(response => {
                if (response.success) {
                    setProducts1(response.data.products);
                }
            });

        apiJson.
            sections.getSectionProducts(
                117,
                props.countryId,
                1,
                10
            )
            .then(response => {
                if (response.success) {
                    setProducts2(response.data.products);
                }
            });

        apiJson.
            sections.getSectionProducts(
                118,
                props.countryId,
                1,
                10
            )
            .then(response => {
                if (response.success) {
                    setProducts3(response.data.products);
                }
            });

        apiJson.
            sections.getSectionProducts(
                119,
                props.countryId,
                1,
                10
            )
            .then(response => {
                if (response.success) {
                    setProducts4(response.data.products);
                }
            });


        apiJson.
            sections.getSectionProducts(
                120,
                props.countryId,
                1,
                10
            )
            .then(response => {
                if (response.success) {
                    setProducts5(response.data.products);
                }
            });



    }, []);

    return (
        <Fragment>
            <div className="product-home-page-desktop mx-auto ramadan-page">
                <WebsiteHeader />
                <div className="mypages-container">
                    <Swiper className="ramadanslider"
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={false}
                        navigation={true}
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/eid-sale"
                            }}>
                                <img
                                    src={TopBanner1}
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                    </Swiper>


                    <div>
                        <span className="ramadantc">
                            <Link className="ramadantclink" to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/eid-terms-and-conditions"
                            }}>
                              <FaInfoCircle style={{ color: '#55555591',marginRight:'3px' }} />View Eid Cashback T&Cs
                            </Link>
                        </span>




                    </div>
                    <div className="emptyspacer"></div>

                    <h5 className="ramadantitle">Top Eid Categories</h5>


                    <Swiper
                        spaceBetween={20}
                        slidesPerView={7}
                        pagination={false}
                        navigation={true}
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=182"
                            }}>
                                <img
                                    src={cat1}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=262"
                            }}>
                                <img
                                    src={cat2}
                                    alt="Alisuq Hand tools"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=667"
                            }}>
                                <img
                                    src={cat3}
                                    alt="Alisuq Kitchen Accessories"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=498"
                            }}>
                                <img
                                    src={cat4}
                                    alt="Kitchen appliances"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=193"
                            }}>
                                <img
                                    src={cat5}
                                    alt="Alisuq Power Tools"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=201"
                            }}>
                                <img
                                    src={cat6}
                                    alt="Alisuq Vacuums"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=212"
                            }}>
                                <img
                                    src={cat7}
                                    alt="Alisuq Water Bottles"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=587"
                            }}>
                                <img
                                    src={cat8}
                                    alt="Alisuq Water Bottles"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=282"
                            }}>
                                <img
                                    src={cat9}
                                    alt="Alisuq Water Bottles"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}
                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=506"
                            }}>
                                <img
                                    src={cat10}
                                    alt="Alisuq Water Bottles"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </SwiperSlide>
                    </Swiper>

                    <div className="mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="surfacepat">
                            <h5 className="ramadantitle">Cement and Concrete Products</h5>
                            <ProductList
                                type="product"
                                home={true}
                                products={products1}
                            />
                        </div>
                    </div>
                    <div className="emptyspacer"></div>
                    <h5 className="ramadantitle">Eid Essentials</h5>


                    <div
                        className="row mx-0 ramadancatpics"
                    >
                        <div className="col-md-2">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=209&filter_by_category%5B%5D=464"
                            }}>
                                <img
                                    src={EidEssential1}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=499&filter_by_category%5B%5D=496"
                            }}>
                                <img
                                    src={EidEssential2}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=226&filter_by_category%5B%5D=227"
                            }}>
                                <img
                                    src={EidEssential3}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=272&filter_by_category%5B%5D=269"
                            }}>
                                <img
                                    src={EidEssential4}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=184&filter_by_category%5B%5D=217"
                            }}>
                                <img
                                    src={EidEssential5}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </div>
                        <div className="col-md-2">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=273&filter_by_category%5B%5D=251"
                            }}>
                                <img
                                    src={EidEssential6}
                                    alt="Alisuq cleaner essentials"
                                    className="img img-fluid"
                                    height="110px"
                                />
                            </Link>
                        </div>
                    </div>

                    <div className="mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="surfacepat">
                            <h5 className="ramadantitle">Steel and Metal Products</h5>
                            <ProductList
                                type="product"
                                home={true}
                                products={products2}
                            />
                        </div>
                    </div>

                    {/* <div className="mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="surfacepat">
                            <h5 className="ramadantitle">Home Improvements</h5>
                            <div className="row ramadanhome">
                                <div className="col-md-6">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=204"
                                    }}>
                                        <img src={CategoryBanner1} className="img img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=186"
                                    }}>
                                        <img src={CategoryBanner2} className="img img-fluid" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="surfacepat">
                            <h5 className="ramadantitle">Wood and Timber Products</h5>
                            <ProductList
                                type="product"
                                home={true}
                                products={products3}
                            />
                        </div>
                    </div>

                    <div className="mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="surfacepat">
                            <div className="row ramadancleaning">
                                <div className="col-md-12">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=279"
                                    }}>
                                        <img src={CleaningBanner1} className="img img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=477"
                                    }}>
                                        <img src={CleaningBanner2} className="img img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=474"
                                    }}>
                                        <img src={CleaningBanner3} className="img img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=525"
                                    }}>
                                        <img src={CleaningBanner4} className="img img-fluid" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="surfacepat">
                            <h5 className="ramadantitle">Plumbing and Piping Products</h5>
                            <ProductList
                                type="product"
                                home={true}
                                products={products4}
                            />
                        </div>
                        <div className="emptyspacer"></div>
                        <div className="ramadancleaning">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_brand%5B%5D=187"
                            }}>
                                <img src={storagebanner} className="img img-fluid" />
                            </Link>
                            <div className="emptyspacer"></div>
                            <div className="surfacepat">
                                <h5 className="ramadantitle">Flooring Products</h5>
                                <ProductList
                                    type="product"
                                    home={true}
                                    products={products5}
                                />
                            </div>
                        </div>
                    </div>

                </div>


                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(EidSale);


