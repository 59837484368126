import React from "react";
import { connect } from "react-redux";

import EidTermsAndConditionsComp from "../../../components/mobile/footer-pages/EidTermsAndConditionsComp";

function EidTermsAndConditions(props) {
  return <div>{props.width === "sm" && <EidTermsAndConditionsComp screen="home" />}</div>;
}

const mapStateToProps = (state) => {
  return {
    width: state.layout.screenWidth,
  };
};

export default connect(mapStateToProps, null)(EidTermsAndConditions);
