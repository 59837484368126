import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiJson } from "../../Api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import pagesbannerimg from "../../assets/images/footer-page-images/offers-top-banner.jpg";
import ramadanimg1 from "../../assets/images/offers/Desktop-HOT-DEALS.jpg";
import ramadanimg2 from "../../assets/images/offers/center-img-desktop.jpg";
import ramadanimg3 from "../../assets/images/offers/right-img-desktop.jpg";
import WebsiteHeader from "../../components/header/WebsiteHeader";
import { set_loading } from "../../redux/actions/LayoutAction";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../assets/styles/footer-pages/offers.scss";
import ProductList from "../../components/product/OffersVariantList";
import Countdown from "react-countdown";

import $ from "jquery";
import OffersMain from "../../components/customer/AllItems/OffersMain";
import VariantsModal from "../../components/customer/VariantsModal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const NewOffersSections = (props) => {
  const [offers_sections, setOffersSections] = useState([]);
  const [top_banner, setTopBanner] = useState();
  const [boxes_banners, setBoxesBanners] = useState([]);
  const { t } = useTranslation();
  const [variantsModalShow, setVariantsModalShow] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedSku, setSelectedSku] = React.useState(null);

  let message = "";
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  let sections = [];

  useEffect(() => {
    document.title = "Alisouq.com - Offers";
  }, []);

  useEffect(() => {
    apiJson.newoffers.getTopBanner(props.countryId).then(async (response) => {
      if (response.success) {
        setTopBanner(response.data);
      }
    });

    apiJson.newoffers.getBoxesBanners(props.countryId).then(async (response) => {
      if (response.success) {
        setBoxesBanners(response.data);
      }
    });

    apiJson.newoffers.offers_sections(props.countryId)
      .then(response => {
        if (response.success) {
          setOffersSections(response.data.sections);
        }
      });
  }, []);

  const onQuickViewClicked = (product, sku) => {
    setVariantsModalShow(true);
    setSelectedSku(sku);

    apiJson.
      singleItem.getItemData(product.id, product.product_type ? "products" : "services", props.countryId)
      .then(response => {
        response.data.type = "Product";

        setSelectedProduct(response.data);
      });
  }

  return (
    <Fragment>
      <div className="product-home-page-desktop mx-auto">
        <VariantsModal
          show={variantsModalShow}
          onHide={() => setVariantsModalShow(false)}
          closeModal={() => setVariantsModalShow(false)}
          product={selectedProduct}
          selected_sku={selectedSku}
        />
        <WebsiteHeader />

        <div className="content-wrapper all-page-container">
          <div className="offers-row">
            <div className="kid-fashion-banner-container">
              <a href="#">

                <div className="pagesbanner">
                  <img
                    src={top_banner?.website_image}
                    alt="category"
                    style={{
                      width: "100%",

                    }}
                  ></img>
                </div>

              </a>
            </div>
          </div>
          <div className="emptyspacer"></div>
          <h2 className="sectiontitle-individualpage">Shop by Brands</h2>
          <div className="emptyspacer"></div>
          <Swiper
            spaceBetween={0}
            slidesPerView={7}
            pagination={false}
            navigation={true}
            className="shop-by-brands"
          >
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=269"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/cBNsVEmg2k6WLXU8DPiF5d.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=164"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/peSAhEhokenQ9EjtR2YGq7.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=282"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/2t7LkpbFt9ZxP2wTBhrNSc.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=277"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/prL5bW2eJnENeohbdUMaNJ.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=273"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/e5M9yfkWTfuaMPJ2ogi3jy.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=270"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/pu7KzRCsUacSKFuNkwCUKr.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=271"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/AjfbvCk3VuBk8cGXvDtNs.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=384"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jj8j7YZJiCyZJJXWgjSx3B.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=292"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jZav9RgF21QuY73Pqqiw2o.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=328"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/iWsLuHBrv6fbjnqahF1NTd.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=487"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/unATv96BjLDmE77JKTNQSf.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=168"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3PwLdMpgncAARimuFawCwK.jpeg"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=195"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ttpWPXDGWaBkJt7fgffp5q.jpeg"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=388"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/uhAC8eaF6vMC1jYjMApUBW.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=283"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/kz1MPy5YVpvYM5t86QUXR7.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=178"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/tP1RDF6oiNm6i9hxUHXKYv.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=281"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/xkavF6T544k2JgQk6wLMMN.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=67"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/9sYGBVBAbzhyBFCoKfQhaQ.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=415"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fDcVJRrX5r45Y3zAUNG8W2.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=171"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/ep7BhDu7z286wNP262KLtt.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=73"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YvGp9qJBuUhj6nhbQX3ue.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=413"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/cjrqdRMj5VQPTBvSRBtAwU.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=453"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/waTUrjpjRM9TYip1d4oHws.jpeg"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=387"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/4c49bVAbDLoqgfHsVcxjbX.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=452"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/863yzQkyrVYPXi2YWN8RQG.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=488"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qToBx3WMZ3TVBUy9GQugeX.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
            <SwiperSlide
              onClick={(e) => e.preventDefault()}
              key={1}
            >
              <Link to={{
                pathname: "/" + props.countryUrl +
                  "-" + props.language + "/items/products",
                search: "filter_by_brand%5B%5D=510"
              }}>
                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/6itpqKYwmo2wSzipBPvXt9.png"
                  className="img-img-fluid paintbrand"
                  height="150px"
                />
              </Link>
            </SwiperSlide>
          </Swiper>
          {
            boxes_banners.length > 0 ?
              <div className="row offerrow">
                {
                  boxes_banners.map((banner, key) => {
                    if (key < 3) {
                      return (
                        <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                          <a href={banner.link}>
                            <img className="img-fluid"
                              src={banner.website_image}
                              alt="category"
                              style={{
                                width: "100%",
                              }}
                            ></img></a>
                        </div>
                      )
                    }
                  })
                }
              </div>
              :
              <></>
            // <div className="row offerrow">

            //   <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            //     <a href="offers/section/hot-deals">
            //       <img className="img-fluid"
            //         src={ramadanimg1}
            //         alt="category"
            //         style={{
            //           width: "100%",
            //         }}
            //       ></img></a>
            //   </div>

            //   <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            //     <a href="offers/section/buy-1-get-1">

            //       <img className="img-fluid"
            //         src={ramadanimg2}
            //         alt="category"
            //         style={{
            //           width: "100%",
            //         }}
            //       ></img>
            //     </a>
            //   </div>
            //   <div className="col-lg-4 col-md-4 col-sm-4 col-4">
            //     <a href="offers/section/summer-specials">

            //       <img className="img-fluid"
            //         src={ramadanimg3}
            //         alt="category"
            //         style={{
            //           width: "100%",
            //         }}
            //       ></img>
            //     </a>
            //   </div>
            // </div>
          }
          {
            offers_sections.map(section => {
              return (
                <Row
                  key={0}
                  className={`product-list-row mx-auto p-0`}
                >
                  <div className="title-row">
                    <div className={props.language == "en" ? "title" : "title text-right"}
                      dangerouslySetInnerHTML={{
                        __html: section.title_en,
                      }}
                    ></div>
                    <div class={props.language == "en" ? "viewalldiv" : "viewalldiv viewalldiv-ar"}>
                      <Link
                        to={
                          "/" + props.countryUrl + "-" + props.language + "/new-offers/section/" + section.url
                        }
                        className="view-all-text">
                        {t("all-categories.view-all")}
                      </Link>
                    </div>
                  </div>
                  <ProductList
                    type="product"
                    home={true}
                    products={section.offers_section_variants}
                    onQuickViewClicked={onQuickViewClicked}
                  />
                  <div />
                </Row>
              )
            })
          }
        </div>

        <WebsiteFooter />

      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    countryId: state.language.countryId,
    language: state.language.language,
  };
};

const mapDispatchToProps = {
  set_loading,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOffersSections);
