import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import WebsiteHeader from "../../components/header/WebsiteHeader";
import WebsiteFooter from "../../components/Footer/WebsiteFooter";
import "../../assets/styles/category-homepages/ali-day.scss";

import ProductList from "../../components/product/ProductList";
import icon1 from "../../assets/images/aliday/discount.png";
import alidaybg from "../../assets/images/aliday/alidaybg1.jpeg";
import extension from "../../assets/images/aliday/extension.png";
import hero2 from "../../assets/images/aliday/hero2.png";
import hero3 from "../../assets/images/aliday/hero3.png";
import hero4 from "../../assets/images/aliday/hero4.png";
import BrandFavourite from "../../assets/images/aliday/Brand-Favourite.gif";
import NeverWet from "../../assets/images/aliday/NeverWet.jpg";


// import alidaybanner from "../../assets/images/aliday/alidaybanner.jpg";
import alidayunit from "../../assets/images/aliday/AliDay_Unit.png";


import catalog1 from "../../assets/images/aliday/Hardware2.jpg";
import catalog2 from "../../assets/images/aliday/paintweb.jpg";
import catalog3 from "../../assets/images/aliday/Electrical2.jpg";
import catalog4 from "../../assets/images/aliday/Automotive2.jpg";

import cat1 from "../../assets/images/aliday/BuildingandConstruction.jpg";
import cat2 from "../../assets/images/aliday/Home-Improvement.jpg";
import cat3 from "../../assets/images/aliday/DailyEssentials.jpg";


import combo1 from "../../assets/images/aliday/combo1.png";
import combo2 from "../../assets/images/aliday/combo2.png";
import combo3 from "../../assets/images/aliday/combo3.png";
import combo4 from "../../assets/images/aliday/combo4.png";

import one from "../../assets/images/individual-pages/home-appliances/VacuumCleaners.png";

import two from "../../assets/images/individual-pages/home-appliances/Washer.png";
import three from "../../assets/images/individual-pages/home-appliances/Heater.png";
import four from "../../assets/images/individual-pages/home-appliances/Microwave.png";
import five from "../../assets/images/individual-pages/home-appliances/Refrigerator.png";
import six from "../../assets/images/individual-pages/home-appliances/Freezer.png";
import seven from "../../assets/images/individual-pages/home-appliances/Juicer.png";

import eight from "../../assets/images/individual-pages/home-appliances/Coffee Maker.png";
import nine from "../../assets/images/individual-pages/home-appliances/Toaster.png";
import ten from "../../assets/images/individual-pages/home-appliances/Aircondition.png";

import CampingTentsImg from "../../assets/images/camping/section2/CampingTents.jpg";
import SleepingBagsImg from "../../assets/images/camping/section2/SleepingBags.jpg";
import CampingFurnitureImg from "../../assets/images/camping/section2/CampingFurniture.jpg";
import CharcoalImg from "../../assets/images/camping/section2/Charcoal.jpg";
import FlashlightImg from "../../assets/images/camping/section2/Flashlight.jpg";
import WaterBottleImg from "../../assets/images/camping/section2/WaterBottle.jpg";
import CampingBackpacksImg from "../../assets/images/camping/section2/CampingBackpacks.jpg";
import CampingUtensilsImg from "../../assets/images/camping/section2/CampingUtensils.jpg";
import CampingCoolersImg from "../../assets/images/camping/section2/CampingCoolers.jpg";
import BBQgrillsImg from "../../assets/images/camping/section2/BBQgrills.jpeg";
import CampingToolsImg from "../../assets/images/camping/section2/CampingTools.jpg";
import CapsCoolnetsImg from "../../assets/images/camping/section2/Caps_coolnets.jpg";
import BinocularsImg from "../../assets/images/camping/section2/Binoculars.jpg";

import eightp from "../../assets/images/individual-pages/paints/All-Purpose-Paint.png";
import fourp from "../../assets/images/individual-pages/paints/Primers-_-Stucco.png";
import ninep from "../../assets/images/individual-pages/paints/Marking-Paints.png";
import threep from "../../assets/images/individual-pages/paints/Exterior-Paint.png";
import onep from "../../assets/images/individual-pages/paints/Rollers.png";
import sevenp from "../../assets/images/individual-pages/paints/Brushes.png";
import fivep from "../../assets/images/individual-pages/paints/Acrylic-Paints.png";
import twop from "../../assets/images/individual-pages/paints/Interior-Paint.png";
import tenp from "../../assets/images/individual-pages/paints/Wood-Finishes.png";
import sixp from "../../assets/images/individual-pages/paints/Epoxy.png";

import { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { apiJson } from "../../Api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

const AliDayPage = (props) => {
    const [products, setProducts] = useState([]);
    const [products2, setProducts2] = useState([]);

    useEffect(() => {
        window.document.title = "ALIDAY SALE - ALISUQ";

        $("html, body").animate({ scrollTop: 0 }, "fast");

        apiJson.
            sections.getCamping1Section(props.countryId)
            .then(response => {
                if (response.success) {
                    setProducts(response.data.section.products);
                }
            });

        apiJson.
            sections.getCamping2Section(props.countryId)
            .then(response => {
                if (response.success) {
                    setProducts2(response.data.section.products);
                }
            });
    }, []);
    return (
        <Fragment>
            <div className="product-home-page-desktop mx-auto ali-day">
                <WebsiteHeader />
                {/* <img
                    src={alidaybanner}
                    className="img img-fluid"
                    width="100%"
                /> */}
                <div style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                }}>
                    <img
                        src={alidayunit}
                        className="img img-fluid"

                    />
                </div>

                <h1 style={{

                    textAlign: "center",
                }}>
                    <span style={{
                        color: "#d4982b",
                        fontFamily: "Futura Heavy",
                    }}>
                        Ali</span>


                    <span style={{
                        color: "#003366",
                        fontFamily: "Futura Heavy",
                    }}>day</span>'s on a quick break<br></br>It'll be back next month from the  <span style={{
                        color: "#d4982b",
                        fontFamily: "Futura Heavy",
                    }}>10th - 14th</span><br></br>Stay tuned for some epic laughs and awesome deals</h1>
                <div className="emptyspacer"></div>
                <div className="emptyspacer"></div>
                <div className="section bg-light-theme herosection">
                    <div className="mypages-container">
                        <div className="row">
                            <div className="col-md-3 d-flex">
                                <img src={icon1} className="topicons" alt="image" />
                                <div className="ml-2">
                                    <h6 className="font-weight-bold mb-1">Mega Discounts</h6>
                                    <p className="my-0">Dive into a world of unbeatable prices on your favorite products</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                <img src={icon1} className="topicons" alt="image" />
                                <div className="ml-2">
                                    <h6 className="font-weight-bold mb-1">No Membership Required</h6>
                                    <p className="my-0">Experience freedom with our subscription-free monthly promotions</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                <img src={icon1} className="topicons" alt="image" />
                                <div className="ml-2">
                                    <h6 className="font-weight-bold mb-1">Every Month, 10th-14th Special</h6>
                                    <p className="my-0">Enjoy exclusive savings and extraordinary deals every month during this limited window</p>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                <img src={icon1} className="topicons" alt="image" />
                                <div className="ml-2">
                                    <h6 className="font-weight-bold mb-1">Freebies & Combos</h6>
                                    <p className="my-0">Discover Freebies & Combos: Extra perks, more joy in every purchase!</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mypages-container">
                    {/* <div className="emptyspacer"></div> */}
                    {/* <div className="catalogsection">

                        <div>

                            <p className="text-center mb-2 alidaysubtitle">Price Drop Guarantee</p>
                            <h1 className="text-center font-weight-bold alidaysecname">Explore Aliday Categories</h1>
                        </div>
                        <div className="row mt-4">
                            <div className="col-4 mt-2 mb-2 catalog1">
                                <Link to={{
                                    pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                    search: "discount_sort=true&filter_by_category%5B%5D=191"


                                }}>
                                    <img
                                        src={catalog1}
                                        alt="category"
                                        className="img img-fluid h-100"
                                    />
                                </Link>
                            </div>
                            <div className="col-8 mt-2 mb-2">
                                <div className="row">
                                    <div className="col-6 mb-2 catalog2">
                                        <Link to={{
                                            pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                            search: "discount_sort=true&filter_by_category%5B%5D=194"
                                        }}>
                                            <img
                                                src={catalog2}
                                                alt="category"
                                                className="img img-fluid"
                                            />
                                        </Link>
                                    </div>
                                    <div className="col-6 mb-2 catalog3">
                                        <Link to={{
                                            pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                            search: "discount_sort=true&filter_by_category%5B%5D=186"
                                        }}>
                                            <img
                                                src={catalog3}
                                                alt="category"
                                                className="img img-fluid"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="mt-2 mb-2 catalog4">
                                    <Link to={{
                                        pathname: "/" + props.countryUrl + "-" + props.language + "/items/products",
                                        search: "discount_sort=true&filter_by_category%5B%5D=193"
                                    }}>
                                        <img
                                            src={catalog4}
                                            alt="category"
                                            height="250px"
                                            width="100%"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_brand%5B%5D=513"
                        }}>
                            <img
                                src={NeverWet}
                                className="img img-fluid neverwetdesk"
                                width="100%"
                            />
                        </Link>

                    </div> */}
                    {/* <div className="section combosec">
                        <div>
                            <p className="text-center mb-2 alidaysubtitle">Limited Time</p>
                            <h1 className="text-center font-weight-bold alidaysecname">Aliday Bundles🎁</h1>
                            <p className="text-center mb-0 alidaysecdetails">Elevate your Aliday experience with our exclusive bundles section—perfect pairings, extraordinary savings.</p>
                        </div>
                        <div className="row mt-5">

                            <div className="col-md-3 text-center">
                                <Link to="paint/rust-oleum-2x-ultra-cover-primer-spray-flat-white-rust-oleum-275660-never-wet-multi-purpose-kit-white-p-90629?sku=249058+275660">
                                    <img
                                        src={combo1}
                                        className="img"
                                        height="250px"
                                    />
                                    <div className="combocontent">
                                        <p className="my-1">
                                            <Link className="category-link comboname">Combo Offer</Link>
                                        </p>
                                        <p className="my-1">
                                            <Link className="category-link comboprice">AED 41</Link>
                                        </p>
                                    </div>
                              
                                </Link>
                            </div>


                            <div className="col-md-3 text-center">
                                <Link to="paint/rust-oleum-stone-spray-paint-pebble-stone-rust-oleum-275619-never-wet-multi-purpose-kit-silver-gray-p-90631?sku=7995830+275619">
                                    <img
                                        src={combo2}
                                        className="img"
                                        height="250px"
                                    />
                                    <div className="combocontent">
                                        <p className="my-1">
                                            <Link className="category-link comboname">Combo Offer</Link>
                                        </p>
                                        <p className="my-1">
                                            <Link className="category-link comboprice">AED 41</Link>
                                        </p>
                                    </div>
                               
                                </Link>
                            </div>

                            <div className="col-md-3 text-center">
                                <Link to="paint/rust-oleum-ultra-matte-paint-linen-white-rustoleum-never-wet-boot-shoe-spray-clear-p-90630?sku=302591+280886">

                                    <img
                                        src={combo3}
                                        className="img"
                                        height="250px"
                                    />
                                    <div className="combocontent">
                                        <p className="my-1">
                                            <Link className="category-link comboname">Combo Offer</Link>
                                        </p>
                                        <p className="my-1">
                                            <Link className="category-link comboprice">AED 41</Link>
                                        </p>


                                    </div>
                                </Link>
                            </div>


                            <div className="col-md-3 text-center">
                                <Link to="paint/rust-oleum-high-heat-flat-black-rust-oleum-fabric-water-repelling-treatment-11oz-p-90628?sku=248903+278146">
                                    <img
                                        src={combo4}
                                        className="img"
                                        height="250px"
                                    />
                                    <div className="combocontent">
                                        <p className="my-1">
                                            <Link className="category-link comboname">Combo Offer</Link>
                                        </p>
                                        <p className="my-1">
                                            <Link className="category-link comboprice">AED 41</Link>
                                        </p>


                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div> */}


                    {/* <img
                        src={BrandFavourite}
                        className="img img-fluid"
                        width="100%"
                    /> */}
                    {/* <div className="emptyspacer"></div> */}




                    {/* <Swiper
                        spaceBetween={10}
                        slidesPerView={8}
                        pagination={false}
                        navigation={true}
                        className="brandfav"
                    >
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=63"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5tFSvZ45KEwSh8toU7PeA8.jpeg" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>

                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=383"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qt1o965TPpRLZLGXy4p1je.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >

                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=372"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8DzBfcT4xfJrK3P23vAUhE.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=168"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/3PwLdMpgncAARimuFawCwK.jpeg" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=384"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/86i7XzSUcpmUFv1nD4sg78.png" className="img-img-fluid sbb" width="100%" />
                            </Link>


                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=124"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/qpTEjuoDGv2bbLunoAua3H.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >

                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=73"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8YvGp9qJBuUhj6nhbQX3ue.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=487"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/unATv96BjLDmE77JKTNQSf.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=178"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/tP1RDF6oiNm6i9hxUHXKYv.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>
                        <SwiperSlide
                            onClick={(e) => e.preventDefault()}
                            key={1}

                        >
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "discount_sort=true&filter_by_brand%5B%5D=288"
                            }}>
                                <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/htuk69yLp7qyNdEFag6TPt.png" className="img-img-fluid sbb" width="100%" />
                            </Link>

                        </SwiperSlide>

                    </Swiper> */}

                    {/* <div className="mt-4 mypages-container">
                        <div className="emptyspacer"></div>
                        <div className="sbcatsection">
                            <div>
                                <h1 className="text-center font-weight-bold alidaysecname">Shop by Category</h1>
                            </div>
                            <div className="emptyspacer"></div>
                            <div className="emptyspacer"></div>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={8}
                                pagination={false}
                                navigation={true}
                                className="sbcswiper"
                            >
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="oddslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=209&filter_by_category%5B%5D=292&filter_by_category%5B%5D=293&filter_by_category%5B%5D=489"
                                    }}>
                                        <img
                                            src={one}
                                            className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Vacuum Cleaners
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="evenslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=206&filter_by_category%5B%5D=291"
                                    }}>
                                        <img
                                            src={two} className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Washers & Dryers
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="oddslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=214"
                                    }}>
                                        <img
                                            src={three} className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Water Heaters
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="evenslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=208"
                                    }}>
                                        <img
                                            src={four} className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Microwaves & Ovens
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="oddslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=205&filter_by_category%5B%5D=290&filter_by_category%5B%5D=289"
                                    }}>
                                        <img
                                            src={five}
                                            className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Refrigerators
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>


                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="evenslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=297"
                                    }}>
                                        <img
                                            src={eight} className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Coffee Maker
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="oddslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=300"
                                    }}>
                                        <img
                                            src={nine} className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Toasters
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                    className="evenslide"
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=310&filter_by_category%5B%5D=449&filter_by_category%5B%5D=448&filter_by_category%5B%5D=450"
                                    }}>
                                        <img
                                            src={ten} className="img-img-fluid sbc"
                                            height="150px"
                                        />
                                        <div className="combocontent">
                                            <p className="my-1 comboname">
                                                Air Conditioners
                                            </p>

                                        </div>
                                    </Link>
                                </SwiperSlide>

                            </Swiper>
                        </div>

                        <div className="emptyspacer"></div>
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/winter-sale"
                        }}>
                            <img
                                src="https://alisuq.com/static/media/1440%20x%20300%20Backpacks%20Desktop.268297a5.jpg"
                                className="img img-fluid"
                                width="100%"
                            />
                        </Link>
                        <div className="emptyspacer"></div>
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={9}
                            pagination={false}
                            navigation={true}
                        >
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=500"
                                }}>
                                    <img
                                        src={CampingTentsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=507"
                                }}>
                                    <img
                                        src={SleepingBagsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=499"
                                }}>
                                    <img
                                        src={CampingFurnitureImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=503"
                                }}>
                                    <img
                                        src={CharcoalImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=505"
                                }}>
                                    <img
                                        src={FlashlightImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=508"
                                }}>
                                    <img
                                        src={WaterBottleImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=498"
                                }}>
                                    <img
                                        src={CampingBackpacksImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=501"
                                }}>
                                    <img
                                        src={CampingUtensilsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=510"
                                }}>
                                    <img
                                        src={CampingCoolersImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=496"
                                }}>
                                    <img
                                        src={BBQgrillsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=495"
                                }}>
                                    <img
                                        src={CampingToolsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=502"
                                }}>
                                    <img
                                        src={CapsCoolnetsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_category%5B%5D=497"
                                }}>
                                    <img
                                        src={BinocularsImg}
                                        className="img img-fluid"
                                        height="110px"
                                    />
                                </Link>
                            </SwiperSlide>
                        </Swiper>
                        <div className="emptyspacer"></div>
                        <div className="emptyspacer"></div>
                        <div className="emptyspacer"></div>
                        <div className="sbcatsection">
                            <div>
                                <h1 className="text-center font-weight-bold alidaysecname">Explore Our Variety of Paints</h1>
                            </div>
                            <div className="emptyspacer"></div>
                            <div className="emptyspacer"></div>
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={8}
                                pagination={false}
                                navigation={true}

                            >
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=423"
                                    }}>
                                        <img
                                            src={onep}
                                            className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=439"
                                    }}>
                                        <img
                                            src={twop} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=440"
                                    }}>
                                        <img
                                            src={threep} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=442"
                                    }}>
                                        <img
                                            src={fourp} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=438"
                                    }}>
                                        <img
                                            src={fivep}
                                            className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=271"
                                    }}>
                                        <img
                                            src={sixp} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=424"
                                    }}>
                                        <img
                                            src={sevenp} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=445"
                                    }}>
                                        <img
                                            src={eightp} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=444"
                                    }}>
                                        <img
                                            src={ninep} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide
                                    onClick={(e) => e.preventDefault()}
                                    key={1}
                                >
                                    <Link to={{
                                        pathname: "/" + props.countryUrl +
                                            "-" + props.language + "/items/products",
                                        search: "filter_by_category%5B%5D=274"
                                    }}>
                                        <img
                                            src={tenp} className="img-img-fluid paintcat"
                                            height="150px"
                                        />
                                    </Link>
                                </SwiperSlide>

                            </Swiper>
                            <div className="emptyspacer"></div>
                        </div>
                        <Link to={{
                            pathname: "/" + props.countryUrl +
                                "-" + props.language + "/items/products",
                            search: "filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273"


                        }}>
                            <img
                                src="https://alisuq.com/static/media/Paints-Primers.e069f360.jpg"
                                className="img img-fluid"
                                width="100%"
                            />
                        </Link>
                        <div className="emptyspacer"></div>
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={7}
                            pagination={false}
                            navigation={true}
                        >
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=42"
                                }}>
                                    <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/hSZ6J8vxQ8pENansr4opLk.png"
                                        className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=45"
                                }}>
                                    <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/8BBQmrEBYX1sD3jqfstdj6.png" className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=48"
                                }}>
                                    <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/fwyPyYsDLWYNkLtUk8MS4E.png" className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=94"
                                }}>
                                    <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/wuiWMpykzKEkkbizxXKKdW.png" className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=329"
                                }}>
                                    <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/6wbn1oxKSWoA1wYVHjRgju.png"
                                        className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=64"
                                }}>
                                    <img src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/jp81DcrwVpXgbTLFPj93Tw.png" className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide
                                onClick={(e) => e.preventDefault()}
                                key={1}
                            >
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/items/products",
                                    search: "filter_by_brand%5B%5D=56"
                                }}>
                                    <img
                                        src="https://alisuq-production-s3-bucket.s3-me-south-1.amazonaws.com/media/images/5yEpHy771FyBamNNRqtbEB.png" className="img-img-fluid paintbrand"
                                        height="150px"
                                    />
                                </Link>
                            </SwiperSlide>



                        </Swiper>

                        <div className="emptyspacer"></div>
                        <div className="emptyspacer"></div>
                    </div> */}
                </div>




                {/* <div className="emptyspacer"></div> */}

                {/* <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={false}
                    navigation={true}
                    loop={true}
                    autoplay={{ delay: 3000 }}
                >
                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >

                        <div className="section bg-light-theme herosec">
                            <div className="mypages-container">

                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/electrical/legrand-5m-cord-5-switched-multi-outlet-extension-universal-sockets-2-usb-p-22524?sku=698501"
                                }}>
                                    <div className="titlewrap">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <h3 className="sectiontitle-individualpage text-left">Aliday Time Bomb Deals⌛</h3>
                                            <h3 className="sectiontitle-individualpage text-left">12: 17: 06: 42</h3>
                                        </div>
                                    </div>
                                    <div className="section row">
                                        <div className="col-7 heroimagecol">

                                            <img
                                                src={extension}
                                                alt="category"
                                                className="img img-fluid h-100"
                                            />

                                        </div>
                                        <div className="col-5">
                                            <h4 className="font-weight-bold">Legrand 5M Cord 5 Switched Multi-Outlet Extension Universal Sockets + 2 Usb</h4>
                                            <h4 className="font-weight-bold heroprice">AED 121.19</h4>
                                            <p className="font-weight-bold herodesc">The Legrand 5M Cord with 5 Switched Multi-Outlet Extensions for Universal Sockets is a power extension cord that allows you to connect multiple devices to a single power source. The extension cord is 5 metres long and has five universal sockets that can accommodate various types of plugs. The extension cord has five individual switches for each socket, allowing you to control the power of each device independently. This feature helps save energy and prevents damage to sensitive devices. The switches are illuminated for ease of use, making it easy to see which devices are powered on or off.</p>
                                            <button type="button" class="quick-view-btn btn btn-secondary">Add To Cart</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >
                        <div className="section bg-light-theme herosec">
                            <div className="mypages-container">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/appliances/karcher-wet-and-dry-vacuum-cleaner-wd-3-s-v-p-11749?sku=16281530"
                                }}>
                                    <div className="titlewrap">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <h3 className="sectiontitle-individualpage text-left">Aliday Time Bomb Deals⌛</h3>
                                            <h3 className="sectiontitle-individualpage text-left">12: 17: 06: 42</h3>
                                        </div>
                                    </div>
                                    <div className="section row">
                                        <div className="col-7 heroimagecol">

                                            <img
                                                src={hero2}
                                                alt="category"
                                                className="img img-fluid h-100"
                                            />

                                        </div>
                                        <div className="col-5">
                                            <h4 className="font-weight-bold">Karcher Wet And Dry Vacuum Cleaner WD 3 S V</h4>
                                            <h4 className="font-weight-bold heroprice">AED 421.28</h4>
                                            <p className="font-weight-bold herodesc">For the jobs too tough for your everyday vacuum, turn to the powerful Kärcher WD 3 S V. This robust machine stands up to the most stubborn dirt around your car, garage and garden.</p>
                                            <div className="herofeatures">
                                                <p>✅ Portable</p>
                                                <p>✅ Cost Effective</p>
                                                <p>✅ Long Lasting</p>
                                                <p>✅ Lightweight</p>
                                                <p>✅ Easy to Use</p>
                                            </div>

                                            <button type="button" class="quick-view-btn btn btn-secondary">Add To Cart</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >

                        <div className="section bg-light-theme herosec">
                            <div className="mypages-container">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/hardware-&-tools/makita-6413-drill-p-15971?sku=6413"
                                }}>

                                    <div className="titlewrap">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <h3 className="sectiontitle-individualpage text-left">Aliday Time Bomb Deals⌛</h3>
                                            <h3 className="sectiontitle-individualpage text-left">12: 17: 06: 42</h3>
                                        </div>
                                    </div>
                                    <div className="section row">
                                        <div className="col-7 heroimagecol">

                                            <img
                                                src={hero3}
                                                alt="category"
                                                className="img img-fluid h-100"
                                            />

                                        </div>
                                        <div className="col-5">
                                            <h4 className="font-weight-bold">Makita Drill</h4>
                                            <h4 className="font-weight-bold heroprice">AED 197.21</h4>
                                            <p className="font-weight-bold herodesc">A drill is a tool used for making round holes or driving fasteners. It's fitted with a bit, either a drill or driver chuck. Hand-operated types are dramatically dwindling in fissionability and cordless battery-powered bones.
                                                Proliferating due to increased effectiveness and ease of use. Drills are generally used in woodworking, metalworking, construction, machine tool fabrication, construction and mileage systems. Especially designed performances are made for atomic operations Drills powered by electricity (or further infrequently, compressed air) are the most common tools in woodworking and machining shops.</p>
                                            <button type="button" class="quick-view-btn btn btn-secondary">Add To Cart</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide
                        onClick={(e) => e.preventDefault()}
                        key={1}
                    >
                        <div className="section bg-light-theme herosec">
                            <div className="mypages-container">
                                <Link to={{
                                    pathname: "/" + props.countryUrl +
                                        "-" + props.language + "/building-and-construction-materials/beorol-sealing-tape-self-adhesive-d-profile-brown-dbr6-p-7257?sku=85104"
                                }}>
                                    <div className="titlewrap">
                                        <div className="d-flex align-items-end justify-content-between">
                                            <h3 className="sectiontitle-individualpage text-left">Aliday Time Bomb Deals⌛</h3>
                                            <h3 className="sectiontitle-individualpage text-left">12: 17: 06: 42</h3>
                                        </div>
                                    </div>
                                    <div className="section row">
                                        <div className="col-7 heroimagecol">

                                            <img
                                                src={hero4}
                                                alt="category"
                                                className="img img-fluid h-100"
                                            />

                                        </div>
                                        <div className="col-5">
                                            <h4 className="font-weight-bold">Beorol Sealing Tape Self Adhesive 'D' Profile Brown DBR6</h4>
                                            <h4 className="font-weight-bold heroprice">AED 21.94</h4>
                                            <p className="font-weight-bold herodesc">Beorol Sealing Tape Self Adhesive 'D' Profile Brown 2 x 3Mtrs DBR6 is a specialized tape that is used for filling empty spaces on doors and windows. It also stops the air flow, dust, moist, heat, cold and noise. Extremely effective and easy to use.</p>
                                            <button type="button" class="quick-view-btn btn btn-secondary">Add To Cart</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper> */}



                {/* <div className="mt-4 mypages-container">
                    <div>
                        <h1 className="text-center font-weight-bold alidaysecname">Categories We Love</h1>
                    </div>
                    <div className="emptyspacer">

                    </div>
                    <div className="row mt-3 catwelove">
                        <div className="col-sm-4">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=182"
                                
                            }}>
                                <img
                                    src={cat1}
                                    alt="category"
                                    className="img img-fluid h-100"
                                />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=189"
                            }}>
                                <img
                                    src={cat2}
                                    alt="category"
                                    className="img img-fluid h-100"
                                />
                            </Link>
                        </div>
                        <div className="col-sm-4">
                            <Link to={{
                                pathname: "/" + props.countryUrl +
                                    "-" + props.language + "/items/products",
                                search: "filter_by_category%5B%5D=184"
                            }}>
                                <img
                                    src={cat3}
                                    alt="category"
                                    className="img img-fluid h-100"
                                />
                            </Link>
                        </div>
                    </div>
                </div> */}

                <div className="w-100">
                    <WebsiteFooter />
                </div>
            </div>
        </Fragment >
    );
};

const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
    };
};


export default connect(mapStateToProps, null)(AliDayPage);


