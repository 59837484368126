import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { InputAdornment } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import $ from "jquery";
import { ClipLoader } from "react-spinners";
import Logo from "../../assets/images/ALISOUQ-LOGO.png";
import Facebook from "../../assets/icons/Icon awesome-facebook-square@2x.png";
import Google from "../../assets/icons/2659939281579738432-512@2x.png";
import "../../assets/styles/user/signup.scss";
import { apiJson } from "../../Api";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { set_user } from "../../redux/actions/AuthAction.js";
import { Redirect, Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import * as Yup from "yup";
import { useFcmPermission } from "../../utils/useFcmPermission.js";
import { useLocation } from "react-router-dom";

require("dotenv").config();

const signupValidation = Yup.object().shape({
  email: Yup.string().email("Must be email").required("Required"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "should be 6 chars minimum")
    .max(32, "should be 32 chars maximum"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Passwords must match"),
});

const Signup = (props) => {
  const { t } = useTranslation();
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState(false);
  const { requestPermission } = useFcmPermission()
  const location = useLocation()
  const referralCode = new URLSearchParams(location.search).get("referral_code")

  let message = "";

  const handleAuthClick = function (provider) {
    $.auth.oAuthSignIn({ provider });
  };

  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showSuccess = () => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (values) => {
    setData(true);
    $.auth
      .emailSignUp({
        first_name_en: values.first_name,
        last_name_en: values.last_name,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      })
      .then((response) => {
        requestPermission();
        localStorage.removeItem("user_key");
        localStorage.removeItem("guest_user_address");
        localStorage.setItem(
          "access_token",
          $.auth.retrieveData("authHeaders")["access-token"]
        );
        localStorage.setItem(
          "client",
          $.auth.retrieveData("authHeaders").client
        );
        localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);
        props.set_user({ isSignedIn: true, user: response.data });
        setData(false);
        referralCode && apiJson.refer.createReferral(referralCode).then()
        setRedirect(true);
        message = "Successfully signed up";
        showSuccess();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'signup',
            'signupMethod': 'email', 
            'page': 'Thank You Page' 
        });
      })
      .fail((error) => {
        setData(false);
        if (error.data.message) {
          message = error.data.message;
          showError();
        } else if (error.data.errors) {
          message = error.data.errors.full_messages
            ? error.data.errors.full_messages[0]
            : error.data.errors[0]
            ? error.data.errors[0]
            : error.data.errors;
          showError();
        } else {
          message = t("errors.network");
          showError();
        }
      });
  };

  var getCookies = function () {
    var pairs = document.cookie?.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      cookies[(pair[0] + "").trim()] = unescape(pair.slice(1).join("="));
    }
    return cookies;
  };

  useEffect(() => {
    if (
      getCookies()?.authHeaders &&
      $?.auth?.retrieveData("authHeaders")?.client
    ) {
      apiJson.auth
        .validateLoginToken({
          headers: {
            client: $?.auth?.retrieveData("authHeaders")?.client,
            "access-token":
              $?.auth?.retrieveData("authHeaders")["access-token"],
            uid: $?.auth?.retrieveData("authHeaders")?.uid,
          },
        })
        .then((response) => {
          const role = response?.data?.data?.roles?.filter(
            (role) => role === "customer"
          )?.length;
          if (response.success && role > 0) {
            localStorage.removeItem("user_key");
            localStorage.setItem(
              "access_token",
              $.auth.retrieveData("authHeaders")["access-token"]
            );
            localStorage.setItem(
              "client",
              $.auth.retrieveData("authHeaders").client
            );
            localStorage.setItem("uid", $.auth.retrieveData("authHeaders").uid);

            props.set_user({
              isSignedIn: true,
              user: response.data.data,
            });
            setRedirect(true);
          } else {
            props.set_user({ isSignedIn: false, user: {} });
            localStorage.removeItem("access_token");
            localStorage.removeItem("client");
            localStorage.removeItem("uid");
            document.cookie =
              "authHeaders=; path=/; expires=Thu, 21 Aug 2014 20:00:00 UTC";
          }
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = "Signup";
  }, []);
  return (
    <Fragment>
      <div className="page-wraper pb-3" style={{ backgroundColor: "white" }}>
        <div className="user-signup">
          <div className="user-signup-container mx-auto px-0">
            <Row noGutters={true}>
              <Col
                md={12}
                className="user-signup-logo-col d-flex flex-column mx-auto px-0"
              >
                <div
                  onClick={() => setRedirect(true)}
                  className="pointer logo-img mx-auto"
                >
                  <img src={Logo} alt="logo" width="250px" />
                </div>
                <div className="signup-create-account-text mx-auto">
                  {t("signup.sign-up-title")}
                </div>
                {props.address ? (
                  <div className="signup-no-account-text mx-auto">
                    {t("signup.already-have-account")}{" "}
                    <span
                      className="text-primary pointer"
                      onClick={() => props.signinClicked(false)}
                    >
                      {t("signup.sign-in")}
                    </span>
                  </div>
                ) : (
                  <div className="signup-no-account-text mx-auto">
                    {t("signup.already-have-account")}
                    {props.language === "en" && (
                      <span className="font-family-arial">? </span>
                    )}
                    <Link to="/login">{t("signup.sign-in")}</Link>
                  </div>
                )}
              </Col>
            </Row>
            <Row
              noGutters={true}
              className="user-signup-form-col justify-content-around align-items-center mx-auto"
            >
              <Col
                md={12}
                lg={{ span: 5 }}
                className="user-signup-form-container"
              >
                <Formik
                  initialValues={initialValues}
                  validationSchema={signupValidation}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {() => {
                    return (
                      <Form className="user-signup-form">
                        <div
                          className={
                            props.direction === "RTL"
                              ? "email-rtl"
                              : "email-ltr"
                          }
                        >
                          <Field
                            className="w-100"
                            component={TextField}
                            name="email"
                            type="email"
                            label={t("signup.email-placeholder")}
                            disabled={false}
                          />
                        </div>
                        <div
                          className={
                            props.direction === "RTL"
                              ? "firstName-rtl"
                              : "firstName-ltr"
                          }
                        >
                          <Field
                            className="w-100"
                            component={TextField}
                            name="first_name"
                            type="text"
                            label={t("signup.first-name")}
                            disabled={false}
                          />
                        </div>
                        <div
                          className={
                            props.direction === "RTL"
                              ? "lastName-rtl"
                              : "lastName-ltr"
                          }
                        >
                          <Field
                            className="w-100"
                            component={TextField}
                            name="last_name"
                            type="text"
                            label={t("signup.last-name")}
                            disabled={false}
                          />
                        </div>
                        <div
                          className={
                            props.direction === "RTL"
                              ? "password-rtl"
                              : "password-ltr"
                          }
                        >
                          <Field
                            className="w-100"
                            component={TextField}
                            name="password"
                            type={showPassword ? "text" : "password"}
                            label={t("signup.password-placeholder")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <MdVisibility />
                                    ) : (
                                      <MdVisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            disabled={false}
                          />
                        </div>
                        <div
                          className={
                            props.direction === "RTL"
                              ? "confirmPassword-rtl"
                              : "confirmPassword-ltr"
                          }
                        >
                          <Field
                            className="w-100"
                            component={TextField}
                            name="password_confirmation"
                            type={showConfirmPassword ? "text" : "password"}
                            label={t("signup.confirm_password-placeholder")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                  >
                                    {showConfirmPassword ? (
                                      <MdVisibility />
                                    ) : (
                                      <MdVisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            disabled={false}
                          />
                        </div>
                        <button
                          type="submit"
                          className="user-signup-form-button"
                          key={509375}
                          disabled={data}
                        >
                          {data ? (
                            <div className="d-flex justify-content-center align-items-center w-100">
                              <ClipLoader color="#fff" loading size={23} />
                            </div>
                          ) : (
                            t("signup.btn-sign-up")
                          )}
                        </button>
                        <p>By creating an account, you agree to Alisuq's&nbsp;<Link to={props.countryUrl + "-" + props.language + "/terms-and-conditions"}>Conditions of Use</Link> and <Link to={props.countryUrl + "-" + props.language + "/privacy-policy"}>Privacy Policy</Link></p>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
              {/* <Col
                md={12}
                lg={1}
                className="vertical-line-with-text d-flex flex-md-row flex-lg-column justify-content-between align-items-center mt-md-5 mt-lg-0"
              >
                <div className="vertical-line-befor-text"></div>
                <div className="vertical-text-between-line">
                  {t("signup.label-or")}
                </div>
                <div className="vertical-line-after-text"></div>
              </Col>
              <Col
                md={11}
                lg={{ span: 5 }}
                className="signup-social-buttons mt-md-5 mt-lg-0"
              >
                <div
                  onClick={() => handleAuthClick("google")}
                  className="signup-with-google pointer"
                >
                  <img src={Google} alt="google" width="21px" height="21px" />

                  {t("signup.btn-with-google")}
                </div>
                <div
                  onClick={() => handleAuthClick("facebook")}
                  className="signup-with-facebook pointer"
                >
                  <img
                    src={Facebook}
                    alt="facebook"
                    width="29px"
                    height="29px"
                  />
                  {t("signup.btn-with-fb")}{" "}
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
      {redirect && !props.address && (
        <Redirect
          push
          to={"/" + props.countryUrl + "-" + props.language + "/"}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    direction: state.language.direction,
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};

const mapDispatchToProps = {
  set_user,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
