import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { set_loading } from "../../../redux/actions/LayoutAction";
import logo from "../../../assets/images/logo.png";
import { connect } from "react-redux";
import { set_vendor_data } from "../../../redux/actions/VendorShopAction";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import MobileLayoutRenderer from "../../../pages/mobile/layout/MobileLayoutRenderer";
import { useTranslation } from "react-i18next";
import { set_country } from "../../../redux/actions/LayoutAction";
import { set_cart_items } from "../../../redux/actions/CartAction";
import { set_redirect_home } from "../../../redux/actions/NotFoundAction";
import "../../../assets/styles/component/mobile/Home/HomeMobile.scss";
import "react-toastify/dist/ReactToastify.css";
import pagesbannerimg from "../../../assets/images/footer-pages-banners/Terms-&-Conditions.jpg";

function EidTermsAndConditionsComp(props) {
  // eslint-disable-next-line
  const [modalShow, setModalShow] = React.useState(true);
  let message = ""; // eslint-disable-next-line
  const { id } = useParams(); // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState({ value: false });
  // eslint-disable-next-line
  const showError = () => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // eslint-disable-next-line
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <MobileLayoutRenderer
      NavbarTop={false}
      BackBtn={false}
      CancelBtn={false}
      NavbarBottom={true}
    >
      <div className="home-mob">
        <div className="header">
          <div className="logo">
            <img src={logo} width="100%" height="100%" alt="" />
          </div>
          <div className="search">
            <RiSearchLine
              onClick={() =>
                setRedirect({ value: true, path: "all-categories" })
              }
            />
          </div>
        </div>

        <div className="body" style={{ background: "#F5F5F5" }}>

          <div className="content-wrapper all-page-container">
            <div
              className="footer-pages-content"
              style={{
                textAlign: "left",
              }}
            >


         <p>
          <span className="eidtermsheading" style={{ fontSize: '20px', fontFamily: 'Futura Heavy', color: '#003366', display: 'block', textAlign: 'center' }}>
            Eid Cashback Offer – Terms & Conditions
          </span>
        </p>

               <b>Introduction</b>
             <p>Welcome to Alisouq’s Eid Cashback Offer! To make your shopping experience even more rewarding, we’re offering 100% cashback on your next order. Please read the following terms and conditions carefully before participating in this campaign.</p>

             <b>Campaign Period</b>
             <p>The Eid Cashback Offer is valid from March 26, 2025, to April 30, 2025.</p>

             <b>Eligibility & Participation</b>
             <p>1. Only customers registered on Alisouq are eligible to participate in the Eid Cashback Offer.</p>
             <p>2. In order to qualify, customers must make a purchase of at least 150 AED on Alisouq.</p>
             <p>3. The first 100 customers who make a minimum purchase of 150 AED will qualify for the 100% cashback.</p>
             <p>4. Cashback is applicable on the first order with a minimum purchase of AED 150 and will be credited to the customer’s Alisouq account after the 7-day return window.</p> 
             <p>5. Cashback will be credited to the customer’s Alisouq account and can be used on a future purchase.</p>

             <b>Cashback Details</b>
             <p>1. The cashback is valid for use until June 1, 2025.</p>
             <p>2. The maximum cashback a customer can receive is AED 50.</p>
             <p>3. Cashback cannot be transferred, exchanged for cash, or used on multiple accounts.</p>
             <p>4. Cashback will be credited to the customer’s Alisouq account after the 7-day return window has ended.</p>

             <b>General Terms</b>
             <p>1. This offer is limited to one cashback per customer during the campaign period.</p>
             <p>2. Orders that are cancelled or refunded will not be eligible for cashback.</p>
             <p>3. This offer cannot be combined with any other discounts or promotions.</p>
             <p>4. Alisouq reserves the right to modify, extend, or cancel this offer at any time without prior notice.</p>
             <p>5. In case of any disputes, Alisouq’s decision will be final.</p>

             <b>What is our cancellation policy?</b>
             <p>Cancellation before shipment: If the order or the item(s) that you want to cancel have not been shipped yet, you can write to our customer support team on customerservice@alisuq.com or call us on +971543064845 (Sunday to Thursday, 8AM to 5PM and Saturday, 8AM to 12PM). In such cases, the order will be cancelled, and the money will be refunded to you within 7-14 days after the cancellation request is duly processed by us.</p>

             <b>How long do I have to return an item?</b>
             <p>You have 7 days from the day of delivery to return any item that meets our return policy.</p>

             <b>What is our return policy?</b>
             <p>Thank you for shopping at Alisouq, we hope you enjoyed your experience! Alisouq offers its customers an ’Easy return policy’, wherein you can raise a return/refund request of a product within 7 days of its delivery.</p>
             <p>- We will refund any item(s) accompanied by original proof of purchase, such as your receipt or tax invoice.</p>
             <p>- The returned item(s) must be in good and unused condition, in its original sealed packaging and with all paperwork, parts and accessories that came along with it.</p>
             <p>- There may be some other items for which returns may not be possible or a restocking fee may apply.</p>

             <b>How will I get refunded for the cancelled orders and how long will this process take?</b>
             <p>In case of cancellation before shipment, we process the refund within 24-48 business hours after receiving the cancellation request.</p>
             <p>- For payments done through credit/debit cards or net banking, the refund will be processed through Alisouq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>
             <p>- For cash on delivery transactions, the refund will be processed through Alisouq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>

             <b>What if I used discount vouchers during the time of payment and I have to cancel my order?</b>
             <p>Discount vouchers are intended for one-time use only and shall be treated as used even if you cancel the order.</p>

             <b>Which are the items that cannot be returned?</b>
             <p>Returns will not be accepted under the following conditions:</p>
             <p>- Product is damaged due to misuse/overuse.</p>
             <p>- Returned without original packaging including, price tags, labels, original packing, freebies and other accessories or if original packaging is damaged.</p>
             <p>- Serial Number is tampered with.</p>
             <p>- Product is used or altered.</p>
             <p>- Free product provided by brand.</p>

             <b>Categories not eligible for Return:</b>
             <p>- Customized Products cannot be returned since they are available during select promotions and ordered on demand.</p>
             <p>- Brand specified which are not returnable.</p>

             <b>Can I return part of my order?</b>
             <p>Yes. A return can be created at item level and if you have ordered multiple items, you can initiate a return/refund for any individual item.</p>

             <b>How will I get refunded for the returned orders and how long will this process take?</b>
             <p>In case of a return/replacement/refund, we process the refund once the products have been received and verified at our warehouse.</p>
             <p>- For payments done through credit/debit cards or net banking, the refund will be processed through Alisouq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>
             <p>- For cash on delivery transactions, the refund will be processed through Alisouq Wallet within 24-48 business hours of us receiving the products back, which can be used during future purchases.</p>

             <b>Will I get my VAT refunded when I return items?</b>
             <p>The VAT amount will be included in your total refund amount. Make sure to show your proof of purchase - receipt or tax invoice - when returning your item(s).</p>

             <b>Is there a cash on delivery charge?</b>
            <p>We will charge an additional AED10 for cash on delivery orders.</p>





            </div>
          </div>
        </div>
        {redirect.value && (
          <Redirect
            push
            to={{ pathname: redirect.path, state: redirect.state }}
          />
        )}
      </div>
    </MobileLayoutRenderer>
  );
}

const mapStateToProps = (state) => {
  return {
    // categories: state.home.categories,
    // products: state.vendor.products,
    // services: state.vendor.services,
    // sections: state.home.sections,
    // positionOne: state.home.positionOne,
    // positionTwo: state.home.positionTwo,
    // positionThree: state.home.positionThree,
    // positionFour: state.home.positionFour,
    // positionFive: state.home.positionFive,
    load: state.layout.loading,
    countryId: state.language.countryId,
    language: state.language.language,
    country: state.language.country,
    countryUrl: state.language.countryUrl,
    redirect: state.notFound.redirect,
  };
};

const mapDispatchToProps = {
  set_vendor_data,
  // set_home_categories,
  set_country,
  set_cart_items,
  set_redirect_home,
  set_loading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EidTermsAndConditionsComp);
