import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import "../../../assets/styles/component/customer/itemDetail/FrequentBoughtMobile.scss";
import { AiOutlinePlus } from "react-icons/ai";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { apiJson } from "../../../Api";
import { connect } from "react-redux";
import { useOutsideAlerter } from "../../../utils/CustomHooks";
import ClipLoader from "react-spinners/ClipLoader";

function FreqProdMobile(props) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const sideCartRef = useRef(null);
    const [showCart, setShowCart] = useState(false);
    useOutsideAlerter(sideCartRef, setShowCart);

    useEffect(() => {
        apiJson.singleItem.getFreqData(props.sku)
            .then(response => {
                let temp_prod = [];

                let first_prod = {
                    prodId: props.product.productId,
                    category_en: props.product.category_title_en,
                    slug: props.product.slug,
                    varId: props.product.variantId,
                    sku: props.product.sku,
                    type: props.product.type,
                    product_type: props.product.product_type,
                    prod_name: props.language == "en" ? props.product.variants?.filter(variant => variant.sku == props.sku)[0].product_name_en : props.product.variants?.filter(variant => variant.sku == props.sku)[0].product_name_ar,
                    variant_name: props.language == "en" ? props.product.variants?.filter(variant => variant.sku == props.sku)[0].name_en : props.product.variants?.filter(variant => variant.sku == props.sku)[0].name_ar,
                    disc_price: props.product.variants?.filter(variant => variant.sku == props.sku)[0].discounted_price,
                    feat_img: props.product.variants?.filter(variant => variant.sku == props.sku)[0].featured_image,
                    isCheck: true,
                }
                temp_prod.push(first_prod);

                response.data.variants.map(variant => {

                    temp_prod.push({
                        prodId: variant.product_id,
                        category_en: variant.category_title_en,
                        slug: variant.product_slug,
                        varId: variant.id,
                        sku: variant.sku,
                        type: props.product.type,
                        product_type: variant.product_type,
                        prod_name: props.language == "en" ? variant.product_name_en : variant.product_name_ar,
                        variant_name: props.language == "en" ? variant.name_en : variant.name_ar,
                        disc_price: variant.discounted_price,
                        feat_img: variant.featured_image,
                        isCheck: true,
                    });


                });
                setProducts(temp_prod);
            })
    }, []);


    const prodimgclick = (e, id) => {
        e.preventDefault();
        let tempprod = Object.assign([], products);
        tempprod?.map(x => {
            if (x.prodId == id) {
                x.isCheck = !x.isCheck;
            }
        })
        setProducts(tempprod);
    }

    const addToCart = (e) => {
        setLoading(true);
        var products_to_add = products?.filter(product => product.isCheck == true);

        if (products_to_add.length >= 1) {
            apiJson.cart.addToCart(
                products_to_add[0].product_type == "wholesale" ?
                    {
                        line_item: {
                            quantity: 1,
                            orderable_type: "Variant",
                            orderable_id: products_to_add[0].varId,
                            product_type: "wholesale"
                        }
                    }
                    :
                    products_to_add[0].type === "Product"
                        ? {
                            line_item: {
                                quantity: 1,
                                orderable_type: "Variant",
                                orderable_id: products_to_add[0].varId,
                            },
                        }
                        : {
                            line_item: {
                                // orderable_type: "Service",
                                // orderable_id: products_to_add[0].id,
                                // start_at: new Date(startDate).toString(),
                                // end_at: new Date(endDate).toString(),
                            },
                        }, props.countryId)
                .then(response => {
                    if (response.success) {
                        if (
                            localStorage.getItem("access_token") ||
                            localStorage.getItem("user_key") === response.data.user_key
                        ) {
                        } else {
                            localStorage.setItem("user_key", response.data.user_key);
                        }
                        if (products_to_add.length >= 2) {
                            apiJson.cart.addToCart(
                                products_to_add[1].product_type == "wholesale" ?
                                    {
                                        line_item: {
                                            quantity: 1,
                                            orderable_type: "Variant",
                                            orderable_id: products_to_add[1].varId,
                                            product_type: "wholesale"
                                        }
                                    }
                                    :
                                    products_to_add[1].type === "Product"
                                        ? {
                                            line_item: {
                                                quantity: 1,
                                                orderable_type: "Variant",
                                                orderable_id: products_to_add[1].varId,
                                            },
                                        }
                                        : {
                                            line_item: {
                                                // orderable_type: "Service",
                                                // orderable_id: products_to_add[1].id,
                                                // start_at: new Date(startDate).toString(),
                                                // end_at: new Date(endDate).toString(),
                                            },
                                        }, props.countryId)
                                .then(response => {
                                    if (response.success) {
                                        if (
                                            localStorage.getItem("access_token") ||
                                            localStorage.getItem("user_key") === response.data.user_key
                                        ) {
                                        } else {
                                            localStorage.setItem("user_key", response.data.user_key);
                                        }

                                        if (products_to_add.length >= 3) {
                                            apiJson.cart.addToCart(
                                                products_to_add[2].product_type == "wholesale" ?
                                                    {
                                                        line_item: {
                                                            quantity: 1,
                                                            orderable_type: "Variant",
                                                            orderable_id: products_to_add[2].varId,
                                                            product_type: "wholesale"
                                                        }
                                                    }
                                                    :
                                                    products_to_add[2].type === "Product"
                                                        ? {
                                                            line_item: {
                                                                quantity: 1,
                                                                orderable_type: "Variant",
                                                                orderable_id: products_to_add[2].varId,
                                                            },
                                                        }
                                                        : {
                                                            line_item: {
                                                                // orderable_type: "Service",
                                                                // orderable_id: products_to_add[2].id,
                                                                // start_at: new Date(startDate).toString(),
                                                                // end_at: new Date(endDate).toString(),
                                                            },
                                                        }, props.countryId)
                                                .then(response => {
                                                    if (response.success) {
                                                        if (
                                                            localStorage.getItem("access_token") ||
                                                            localStorage.getItem("user_key") === response.data.user_key
                                                        ) {
                                                        } else {
                                                            localStorage.setItem("user_key", response.data.user_key);
                                                        }
                                                        setShowCart(true);
                                                        setLoading(false);
                                                    }
                                                })
                                        }
                                        else {
                                            setShowCart(true);
                                            setLoading(false);
                                        }
                                    }
                                })
                        }
                        else {
                            setShowCart(true);
                            setLoading(false);
                        }
                    }
                });
        }
    }



    return (

        <div className="freqprod">

            <div className="freqprodcont">

                {

                    products?.map((product, i) => {
                        return (
                            <div className="freqprod1 fp">
                                <Link
                                    to={{
                                        pathname:
                                          "/" +
                                          props.countryUrl +
                                          "-" +
                                          props.language +
                                          `/${product.category_en?.toLowerCase().replace(/ /g, "-")}/${product.slug
                                          }`,
                                        state: { id: product.prodId, type: product.type, sku: product.sku },
                                        search: "?sku=" + product.sku
                                      }}
                                >
                                <div className="freqprodcard">

                                    <div className="addcheck" onClick={(e) => prodimgclick(e, product.prodId)}>
                                        {product.isCheck ?
                                            <MdCheckBox color="#003366" size={20} />
                                            :
                                            <MdCheckBoxOutlineBlank color="#003366" size={20} />
                                        }

                                    </div>

                                    <div className="imgholder">
                                        <img src={product.feat_img}></img>
                                    </div>

                                    <p className="freqprodname">{product.prod_name + " " + product.variant_name}</p>
                                    <div className="freqprice">
                                        <span>  {props.country.toLowerCase() === "saudi arabia"
                                            ? "SAR"
                                            : props.country.toLowerCase() === "united arab emirates"
                                                ? "AED"
                                                : "EGP"}&nbsp;{product.disc_price}</span>
                                    </div>

                                </div>
                                </Link>
                                {
                                    i < (products.length - 1) ?
                                        <div className="plusdiv">
                                            <AiOutlinePlus size={20} />
                                        </div>
                                        :
                                        <></>
                                }

                            </div>

                        )
                    })
                }

            </div>
            <div className="freqadddiv">

                {
                    loading ?
                        <button className="loading-btn" disabled>
                            <ClipLoader size={22} color="white" />
                        </button>
                        :
                        products.filter(product => product.isCheck == true).length == 1 || products.filter(product => product.isCheck == true).length == 0 ?

                            <button onClick={addToCart} className={products.filter(product => product.isCheck == true).length == 0 ? "freqaddinactivebtn" : "freqadd"} disabled={products.filter(product => product.isCheck == true).length == 0 ? true : false}>Buy</button>
                            :
                            <button onClick={addToCart} className="freqadd">Buy {products.filter(product => product.isCheck == true).length}
                                &nbsp;together for &nbsp;
                                {props.country.toLowerCase() === "saudi arabia"
                                    ? "SAR"
                                    : props.country.toLowerCase() === "united arab emirates"
                                        ? "AED"
                                        : "EGP"}
                                &nbsp;
                                {(products.filter(product => product.isCheck == true).reduce((acc, curr) => acc + parseFloat(curr.disc_price), 0)).toFixed(2)}



                            </button>
                }


            </div>
            {showCart && (
                <Redirect
                    push
                    to={"/" + props.countryUrl + "-" + props.language + "/cart"}
                />
            )}
        </div>

    );

}


const mapStateToProps = (state) => {
    return {
        countryUrl: state.language.countryUrl,
        countryId: state.language.countryId,
        language: state.language.language,
        country: state.language.country,
    };
};


export default connect(mapStateToProps, null)(FreqProdMobile);
